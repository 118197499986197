/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AddCardModal from "../../Modals/addCardModal";
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from "formik";
import * as Yup from "yup";
import { STRIPE_TEST_KEY } from "../../constants/url";
import { useLazyGetCardsApiQuery } from "../../services/auth";
import { Loader, showError, showToast } from "../../constants";
import { CardData } from "../../types/General";
import { useDeleteCardMutation } from "../../services/cart";
import { WarnModal } from "../../components";

export default function ManageCard() {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [deleteId, setDeleteId] = useState<string>();
  const handleCloseModal = () => {
    setOpen(false);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getAllCards] = useLazyGetCardsApiQuery();
  const [cards, setCards] = useState<CardData[]>([]);
  const [deleteCard] = useDeleteCardMutation();
  console.log(cards, "cards");

  const fetchAllCards = async () => {
    setIsLoading(true)
    try {
      const res = await getAllCards({}).unwrap();
      if (res?.status === 200) {
        setIsLoading(false)
        setCards(res?.data?.data)
      }
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error)
    }
  }

  const deleteCardFun = async () => {
    setIsLoading(true)
    const body = {
      card_id: deleteId,
    }
    console.log(body, "body for delete");

    try {
      const res = await deleteCard(body).unwrap();
      if (res?.status === 200) {
        setIsLoading(false)
        showToast("Card deleted successfully")
        fetchAllCards();
      }
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error)
    }
  }

  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "Visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };



  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  useEffect(() => {
    fetchAllCards();
  }, [])


  return (
    <div
      className="tab-pane fade"
      id="v-pills-Cards"
      role="tabpanel"
      aria-labelledby="v-pills-Cards-tab"
    >
      <Loader isLoad={isLoading} />
      <div className="account_head">
        <h3>Manage Cards</h3>
      </div>
      <div className="payment_list">

        {
          cards?.length ?
            (
              cards?.map((item) => {
                return (
                  <label>
                    {/* <a href="javascript:void(0)" onClick={() => setOpen(true)}></a> */}
                    <figure>
                      <img src={cardImage(item?.brand)} alt="img" />
                    </figure>
                    <span>
                      <strong>•••• •••• •••• {item?.last4}</strong>
                      {item?.brand} - {item?.exp_month}/{item?.exp_year}
                    </span>
                    <p className="c_danger"
                      onClick={() => { setDeleteId(item?.id); setOpen1(true); }}
                    >
                      Delete
                    </p>

                  </label>
                )
              })
            ) : (
              ""
            )
        }

        <a href="javascript:void(0)" className="add_payment">
          <span>
            <AddIcon onClick={() => setOpen(true)} />
          </span>
          <p className="c_primary" onClick={() => setOpen(true)}>
            Add New Card
          </p>
          <p>Save and Pay via Cards.</p>
          <img src="static/images/payment_icon.svg" alt="Icon" />
        </a>
      </div>
      <AddCardModal open={open} onClose={handleCloseModal} setOpen={setOpen} fetchAllCards={fetchAllCards} />
      <WarnModal
        open={open1}
        setOpen={setOpen1}
        handleDelete={deleteCardFun} // Use the wrapper function with no parameters
        name={"card"}
      />
    </div>

  );
}
