import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddressModal } from "../../Modals/addAddressModal";
import {
  useDeleteAddressMutation,
  useLazyGetAllAddressQuery,
} from "../../services/profile";

import { UserAddress } from "../../types/General";
import { showError, showToast } from "../../constants";
import { WarnModal } from "../../components";

type AddressProps = {
  addressData: UserAddress[];
  getAllAddress: () => void;
};

export default function ManageAddress({
  addressData,
  getAllAddress,
}: AddressProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [addressById, setAddressById] = useState<UserAddress | any>();
  const [isAdd, setIsAdd] = useState(false);
  const [deleteAddress] = useDeleteAddressMutation();
  const [open1, setOpen1] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(); // Store the ID to be deleted

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await deleteAddress({ id }).unwrap();
      showToast("Address deleted successfully");
      getAllAddress();
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const openWarnModal = (id: number | undefined) => {
    setDeleteId(id); // Set the ID of the item to be deleted
    setOpen1(true); // Open the warning modal
  };

  const handleDeleteWrapper = () => {
    if (deleteId) {
      handleDelete(deleteId); // Call the actual delete function with the stored ID
    }
  };

  return (
    <div className="tab-pane">
      <div className="account_head">
        <h3>Manage Addresses</h3>
        <div className="btn_flex">
          <Button
            className="btn_xsm btn_primary"
            onClick={() => {
              setOpen(true);
              setIsAdd(true);
            }}
          >
            Add New Address
          </Button>
        </div>
      </div>
      <div className="address_list">
        {addressData?.length > 0 ? (
          addressData?.map((item) => (
            <label key={item?.id}>
              <span>
                <strong>
                  {item?.first_name} {item?.last_name}
                </strong>
                {item?.address} {item?.city}, {item?.zipcode}
              </span>
              <div style={{ display: "flex", gap: "15px" }}>
                <span
                  onClick={() => {
                    setOpen(true);
                    setAddressById(item);
                    setIsAdd(false);
                  }}
                  className="c_edit"
                >
                  Edit
                </span>
                <span
                  onClick={() => {
                    openWarnModal(item?.id); // Open the warning modal with the item's ID
                  }}
                  className="c_danger"
                >
                  Delete
                </span>
              </div>
            </label>
          ))
        ) : (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src="/static/images/no_address.png"
                alt="icon"
                style={{ height: "100px", width: "100px", margin: "auto" }}
              />
              <h5>No address added</h5>
            </div>
          </div>
        )}
      </div>
      <AddressModal
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        getAllAddress={getAllAddress}
        addressById={addressById}
        isAdd={isAdd}
      />
      <WarnModal
        open={open1}
        setOpen={setOpen1}
        handleDelete={handleDeleteWrapper} // Use the wrapper function with no parameters
        name={"address"}
      />
    </div>
  );
}
