import React, { useEffect, useState } from "react";
import { useLazyGetAllCategoriesQuery } from "../../services/main";
import { Subcategory } from "../../types/General";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../constants";



export default function PopularTherapies() {

  const [getCategories, { isLoading }] = useLazyGetAllCategoriesQuery();
  const [categories, setCategories] = useState<Subcategory[]>([]);
  const [viewAll, setViewAll] = useState<boolean>(false);
  const buttonText = viewAll ? "Show less" : "View all";
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await getCategories({ search: "" }).unwrap();
      if (res?.status === 200) {
        setCategories(res?.subcategory);
      }
    } catch (errors: any) {
      console.log(errors?.error);
    }
  }

  const notPopular = categories.filter((item) => item?.populartherapy === 0);
  const popular = categories.filter((flag) => flag?.populartherapy === 1);
  const sortedCategories = [...popular, ...notPopular];

  console.log(sortedCategories, "sorted ");

  useEffect(() => {
    fetchData();
  }, [])

  const handleViewAllClick = () => {
    if (!viewAll) {
      window.scrollBy({ top: 600, behavior: 'smooth' });
    }
    setViewAll(!viewAll);
  }

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="specialities_sc ut_spc uhb_spc">
        <div className="conta_iner">
          <div className="s_head" >
            <h2 style={{ textAlign: "center" }}>Popular Therapies</h2>
            {sortedCategories?.length > 8 && (
              <Button
                className="btn btn_xsm btn_primary"
                onClick={handleViewAllClick}
              >
                {buttonText}
              </Button>
            )}
          </div>


          <div className="gap_m hd_5" >
            {sortedCategories?.length
              ? sortedCategories.slice(0, viewAll ? sortedCategories.length : 8).map((item, index) => {
                return (
                  <div key={index} className="specialities_box">
                    <figure
                      onClick={() => navigate("/docterlisting", { state: { search: "", value: "", category: item?.sub_categories_name } })}
                    >
                      <img src={item?.image ? item?.image : "static/images/icon_05.svg"} alt="static/images/icon_05.svg" />
                    </figure>
                    <h3
                      onClick={() => navigate("/docterlisting", { state: { search: "", value: "", category: item?.sub_categories_name } })}
                      style={{ cursor: "pointer" }}>{item?.sub_categories_name}</h3>
                  </div>
                )
              })
              : undefined}
          </div>
        </div>
      </section >
      <section className="service_sc u_spc">
        <div className="conta_iner">
          <div className="s_head gap_p">
            <div style={{ textAlign: "center" }}>
              <h2>We will serve you with healthcare services</h2>
            </div>
            {/* <div className="right_s">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <a href=" " className="btnn btn_xsm btn_primary">
                Learn More
              </a>
            </div> */}
          </div>
          <div className="gap_m hd_4">
            <div className="service_box"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/docterlisting", { state: { search: "", value: "", category: "" } });
                window.scrollTo(0, 0);
              }}
            >
              <h3>Make <br /> Appointment</h3>
              <p>
                <small>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </small>
              </p>
              <figure>
                <img src="static/images/service_attachment_01.png" alt="icon" />
              </figure>
            </div>
            <div className="service_box"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/docterlisting", { state: { search: "", value: 2, category: "" } });
                window.scrollTo(0, 0);
              }}
            >
              <h3>Virtual <br /> Consultation</h3>
              <p>
                <small>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </small>
              </p>
              <figure>
                <img src="static/images/service_attachment_02.png" alt="icon" />
              </figure>
            </div>
            <div className="service_box"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/docterlisting", { state: { search: "", value: 1, category: "" } });
                window.scrollTo(0, 0);
              }}
            >
              <h3>Visit <br /> Doctor Clinic</h3>
              <p>
                <small>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </small>
              </p>
              <figure>
                <img src="static/images/service_attachment_03.png" alt="icon" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="consulation_sc">
        <div className="conta_iner">
          <div className="gap_p aic">
            <div className="left_s">
              <h2>Consultation with our professional doctors</h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using ‘Content
                here, content here’, making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for ‘lorem
                ipsum’ will uncover many web sites still in their infancy.
              </p>
              <ul>
                <li>24/7</li>
                <li>100+</li>
                <li>1M+</li>
              </ul>
            </div>
            <div className="right_s">
              <figure>
                <img src="static/images/doctor_attachment.png" alt="Icon" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
