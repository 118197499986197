import { END_POINTS } from "../constants/url";
import { Doctors, Review, UserAddress } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
    status: number;
    mesaage: string;
    token: string;
    user: User;
    message: string;
};


type RatingResponse = {
    message: string;
    status: number;
    review: Review;
}

type updateBody = {
    review: string;
    rating: number;
    id: number;
}

export const ratingApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postRatings: builder.mutation<CommonResponseType & { data: Review }, Review>({
            query: (body) => ({
                url: END_POINTS.addReview,
                method: "POST",
                body
            })
        }),
        getRating: builder.mutation<
            RatingResponse
            , { id: number }>({
                query: (body) => ({
                    url: END_POINTS.getReview,
                    method: "POST",
                    body
                })
            }),
        updateRating: builder.mutation<CommonResponseType & { data: Review }, updateBody>({
            query: (body) => ({
                url: END_POINTS.updateReview,
                method: "POST",
                body
            })
        }),
    })
})

export const {
    usePostRatingsMutation,
    useGetRatingMutation,
    useUpdateRatingMutation,

} = ratingApi;