export const STORAGE_KEYS = {
  token: "TOKEN",
  credentials: "CREDENTIALS",
  phoneCredentials: "PHONE_CREDENTIALS",
  tempToken: "TEMP_TOKEN",
  fcmToken: "FCM_TOKEN",
  res: "RES",
  stepID: "STEP_ID",
  userId: "USER_ID",
};
