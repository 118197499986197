import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAddTocartMutation, useLazyEmptyCartQuery } from "../services/cart";
import { showError, showToast } from "../constants";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    body: {
        product_id: number,
        quantity: number,
        outlet: number | any,
    }
    setShowAdd: Dispatch<SetStateAction<boolean>>;
};

const SameOutletWarning = ({ open, setOpen, body, setShowAdd }: props) => {
    const [emptyCart] = useLazyEmptyCartQuery();
    const [addToCart, { isLoading }] = useAddTocartMutation();

    const handleDelete = async () => {
        try {
            const res = await emptyCart({}).unwrap();
            if (res?.status === 200) {
                showToast("Cart cleared successfully")
            }
        } catch (errors: any) {
            console.log(errors?.error);
        }
    }

    const addItems = async () => {
        try {
            const res = await addToCart(body).unwrap();
            if (res?.status === 200) {
                setShowAdd(false);
            }
            if (res?.status === 220) {
                showError("Sorry, item is out of stock")
            }
        } catch (errors: any) {
            showError(errors)
        }
    }


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="logout_mdl"
        >
            <div className="modal-dialog">
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "#ffff",
                    boxShadow: 24,
                    p: 6,
                    borderRadius: 3,
                    outline: "none",
                }}>
                    <div
                        onClick={() => setOpen(false)}
                        style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            cursor: "pointer"
                        }}
                    >
                        <CloseIcon />
                    </div>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ textAlign: "center", fontSize: "20px", color: "#000000" }}
                    >
                        Sorry you can't add products from diffrent stores , to add this product you have to empty your cart.
                    </Typography>
                    <Box sx={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                        <Button
                            // className="yesBtn"
                            sx={{ color: "#ffffff", border: "1px solid #252525", bgcolor: "#29AB87", margin: "5px", fontSize: "20px", fontWeight: "550" }}
                            onClick={() => {
                                handleDelete();
                                addItems();
                                setOpen(false);
                            }}
                        >
                            Clear cart
                        </Button>

                        <Button
                            sx={{ color: "#ffffff", border: "1px solid #252525", bgcolor: "#29AB87", margin: "5px", fontSize: "20px", fontWeight: "550" }}
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </Button>
                    </Box>
                </Box>
            </div >
        </Modal >
    );
};

export default SameOutletWarning;
