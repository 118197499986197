/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Layout } from "../../layouts";
import { useLocation, useNavigate } from "react-router-dom";
import { AddCardModal, AddressModal } from "../../Modals";
import { useLazyGetAllAddressQuery } from "../../services/profile";
import { CardData, UserAddress } from "../../types/General";
import { Loader, STORAGE_KEYS, getFromStorage, showError, showToast } from "../../constants";
import { useLazyGetCardsApiQuery } from "../../services/auth";
import AddIcon from "@mui/icons-material/Add";
import { useCartPayNowMutation } from "../../services/cart";
import { STRIPE_TEST_KEY } from "../../constants/url";


export const ProductCheckout = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [getAllCards] = useLazyGetCardsApiQuery();
  const userId = getFromStorage(STORAGE_KEYS.userId);
  const [getAddress] = useLazyGetAllAddressQuery();
  const [addressData, setAddressData] = useState<UserAddress[]>([]);
  const { state } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [selectedAddId, setSelectedAddId] = useState<any>();
  const [cards, setCards] = useState<CardData[]>([]);
  const [selectedCard, setSelectedCard] = useState<any>();
  const [data, setData] = useState<any>(state);
  const [payNow] = useCartPayNowMutation();
  console.log(data, "state data");
  console.log(addressData, "addressData");

  const fetchPayNow = async () => {
    setIsLoading(true);
    const body = {
      product: [
        {
          id: data?.product[0]?.id,
          name: data?.product[0]?.name,
          price: data?.product[0]?.price,
          quantity: 1,
        }
      ],
      amount: Number(data?.product[0]?.price),
      delivery_address: selectedAddress,
      delivery_address_id: selectedAddId,
      card_id: selectedCard?.id,
      shipping_charges: data?.shipping_charges,
      outlet_id: data?.product[0]?.outlet_id
    }
    console.log(body, "body for payment");

    if (!selectedAddId) {
      showError("Please select an address")
      setIsLoading(false);
      return;
    }

    if (!selectedCard) {
      showError("Please select a card")
      setIsLoading(false);
      return;
    }

    try {
      const res = await payNow(body).unwrap();
      setIsLoading(false);
      if (res?.status === 200) {
        showToast("Order placed successfuly")
        navigate("/payment-done", { replace: true })
      }

    } catch (errors: any) {
      setIsLoading(false);
      // showError(errors?.error)
    }
  }

  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "Visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };


  const fetchAllCards = async () => {
    try {
      const res = await getAllCards({}).unwrap();
      if (res?.status === 200) {
        setCards(res?.data?.data)
        setSelectedCard(res?.data?.data[0])
      }
    } catch (errors: any) {
      showError(errors?.error)
    }
  }



  const getAllAddress = async () => {
    try {
      const res = await getAddress({ user_id: userId }).unwrap();
      if (res?.status === 200) {
        setAddressData(res?.addresses);
        setSelectedAddress(res?.addresses[0]?.address)
        setSelectedAddId(res?.addresses[0]?.id)
      }
    } catch (error: any) {
      showError(error?.errors || "");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseModal1 = () => {
    setOpen1(false);
  };

  const fun = async () => {
    return "";
  }



  useEffect(() => {
    getAllAddress();
    fetchAllCards();
  }, []);

  // useEffect(() => {
  //   setPaymentData({
  //     product: [
  //       {
  //         id: data?.product[0]?.id,
  //         name: data?.product[0]?.name,
  //         price: data?.product[0]?.price,
  //         quantity: data?.product[0]?.quantity,
  //       }
  //     ],
  //     amount: Number(data?.product[0]?.price),
  //     delivery_address: selectedAddress,
  //     delivery_address_id: selectedAddId
  //   })
  // }, [])



  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);


  return (
    <>
      <Layout>
        <Loader isLoad={isLoading} />
        <main className="content productCheckout_page">
          <section className="site_breadcrum">
            <div className="conta_iner">
              <ul>
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/store-listing")}>
                    Store Listing
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/store-details")}>Store Detail</a>
                </li>
                <li>
                  <a onClick={() => navigate(`/product-details/${data?.product[0]?.id}`)}>
                    Product Detail
                  </a>
                </li>
                <li>Checkout</li>
              </ul>
            </div>
          </section>

          <section className="productCheckout_sc ub_spc">
            <div className="conta_iner">
              <div className="product_info">
                <figure className="product_image">
                  <img
                    src={data?.productimages[0] ? data?.productimages[0] : "img"}
                    alt="icon"
                  />
                </figure>
                <div className="right_info hd_3">
                  <h2>
                    {data?.product[0]?.name || "-"}
                  </h2>
                  <p>{data?.product[0]?.category?.name || "-"}</p>
                  <h3>$ {data?.product[0]?.price || "-"}</h3>
                </div>
              </div>

              <div className="checkout_address">
                <div className="d_flex">
                  <h3>Address</h3>
                  <a
                    href="javascript:void(0)"
                    onClick={() => setOpen(true)}
                    data-bs-toggle="modal"
                    data-bs-target="#addAddressModal"
                  >
                    Add
                  </a>
                </div>
                <div className="address_list">
                  {addressData?.length ?
                    addressData?.map((item) => {
                      return (
                        <label>
                          <span>
                            <strong>{item?.address}</strong>
                            {item?.city + "," + item?.zipcode}
                            <p>{item?.phone}</p>
                          </span>
                          <input
                            type="radio"
                            name="address"
                            checked={selectedAddId === item?.id}
                            value={selectedAddId}
                            onClick={() => { setSelectedAddress(item?.address); setSelectedAddId(item?.id) }} />
                        </label>
                      )
                    }) : (
                      ""
                    )
                  }

                </div>
              </div>

              <div className="payment_box">
                <h3>Payment Detail</h3>
                <div className="p_group">
                  <p>Shipping fee <strong>$10</strong></p>
                  <p>Total Cost <strong>${Number(data?.product[0]?.price)}</strong></p>
                </div>
                <h3>Online Payments</h3>
                <div className="payment_list">
                  {cards?.map((item) => {
                    return (
                      <label>
                        <figure>
                          <img src={cardImage(item?.brand)} alt="img" />
                        </figure>
                        <span>
                          <strong>•••• •••• •••• {item?.last4}</strong>
                          {item?.brand} - {item?.exp_month}/{item?.exp_year}
                        </span>
                        <input
                          type="radio"
                          name="payment"
                          value="payment1"
                          checked={selectedCard?.id === item?.id}
                          onClick={() => setSelectedCard(item)} />
                      </label>
                    );
                  })}
                  <a
                    href="javascript:void(0)"
                    onClick={() => setOpen1(true)}
                    className="add_payment"
                  >
                    <span>
                      <AddIcon />
                    </span>
                    <p className="c_primary">Add New Card</p>
                    <p>Save and Pay via Cards.</p>
                    <img src="static/images/payment_icon.svg" alt="Icon" />
                  </a>
                </div>

                <div className="btn_flex">
                  <a
                    onClick={() => fetchPayNow()}
                    className="btnn btn_xsm btn_primary br w_100"
                  >
                    Pay Now
                  </a>
                </div>
              </div>
              <AddCardModal open={open1} onClose={handleCloseModal1} setOpen={setOpen1} fetchAllCards={fetchAllCards} />
              {/* <PaymentDetailsCard appointmentData={paymentData} price={String(paymentData?.amount)} from="purchase" /> */}
            </div>
          </section>
        </main>
        <AddressModal
          open={open}
          setOpen={setOpen}
          onClose={handleCloseModal}
          getAllAddress={getAllAddress}
          addressById={""}
          isAdd={true}
        />
      </Layout>
    </>
  );
};
