/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { SimilarProductCard } from "../../components";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router";
import StarIcon from '@mui/icons-material/Star';
import { useLazyGetStoreDetailsQuery } from "../../services/store";
import { Product, ProductUpdated, StoreData, StoreDetailUpdated } from "../../types/General";
import { Loader, showError } from "../../constants";
import { useParams } from "react-router-dom";
import moment from "moment";


export const StoreDetail = () => {
  const { id } = useParams();
  const [storeDetails, { isLoading }] = useLazyGetStoreDetailsQuery();
  const [productsData, setProductsData] = useState<ProductUpdated[]>([]);
  const [details, setDetails] = useState<StoreDetailUpdated>();
  const navigate = useNavigate();

  const fun = async () => "";

  const fetchDetails = async () => {
    try {
      const res = await storeDetails({ id: id || "" }).unwrap();
      if (res.status === 200) {
        setDetails(res?.store);
        setProductsData(res?.store?.data[0]?.products || []);
      }
    } catch (error: any) {
      showError("Error fetching details")
    }
  }

  const isStoreOpen = (openingTime: any, closingTime: any) => {
    const currentTime = moment();
    const opening = moment(openingTime, 'HH:mm:ss');
    const closing = moment(closingTime, 'HH:mm:ss');
    return currentTime.isBetween(opening, closing);
  };



  const storeIsOpen = isStoreOpen(details?.data[0]?.opening_time, details?.data[0]?.closing_time);

  useEffect(() => {
    fetchDetails();
  }, [id]);


  return (
    <>
      <Layout>
        <Loader isLoad={isLoading} />
        <main className="content storeListing_page">
          <section className="site_breadcrum">
            <div className="conta_iner">
              <ul>
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/store-listing")}>
                    Store Listing
                  </a>
                </li>
                <li>{details?.data[0]?.merchant[0]?.store_name}</li>
              </ul>
            </div>
          </section>

          <section className="storeDetail_sc ub_spc">
            <div className="conta_iner">
              <div className="gap_p">
                <div className="left_s">
                  <figure className="store_image">
                    <img
                      src={details?.data[0]?.images ? details?.data[0]?.images : "/static/images/store_attachment_01.jpg"}
                      alt="icon"
                    />
                  </figure>
                </div>
                <div className="right_s hd_3">
                  <div className="detail_area">
                    {/* <p className="c_primary">Herbal</p> */}
                    <h2>{details?.data[0]?.name}</h2>
                    <p>Timing: {moment(details?.data[0]?.opening_time, 'HH:mm:ss').format('hh:mm A') || ''} to {moment(details?.data[0]?.closing_time, 'HH:mm:ss').format('hh:mm A') || ''}</p>
                    <address className="store_address">
                      {details?.data[0]?.address}
                    </address>
                    {/* <div className="store_rating">
                      <StarIcon /> {formattedRating(details?.rating)}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="detailed_info">
                <div className="hd_3">
                  <h2 className="fw_med">About Store</h2>
                </div>
                <p>
                  {details?.data[0]?.about}
                </p>
              </div>
              {productsData?.length ? (
                <div className="products_sc hd_3">
                  <h2 className="fw_med">Products</h2>
                  <div className="product_listing">
                    <div className="gap_m 
                  scrollable-similar"
                      style={{ padding: "5px" }}
                    >
                      {productsData?.map((item) => {
                        return (
                          <SimilarProductCard
                            status={storeIsOpen ? "open" : "close"}
                            key={details?.data[0]?.id}
                            fetchData={fun}
                            outlet_id={item?.outlet_id}
                            product_id={Number(item?.id)}
                            product_image={item?.productimage}
                            product_name={item?.name}
                            product_desc={item?.about}
                            product_price={item?.price}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (<h2>No Product available</h2>)}

            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};
