import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Checkbox, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLazyDeleteCartItemQuery, useUpdateItemMutation } from "../services/cart";
import { Loader, showError, showToast } from "../constants";
import { CartItemType } from "../types/General";
import { useNavigate } from "react-router-dom";

type CartItems = {
  cartData: CartItemType;
  fetchData: () => void;
  setSelected: Dispatch<SetStateAction<CartItemType[]>>;
};

const CartItem = ({
  fetchData,
  cartData,
  setSelected
}: CartItems) => {
  const [deleteItem] = useLazyDeleteCartItemQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quan, setQuan] = useState<number>(cartData?.quantity);
  const [isChecked, setIsChecked] = useState<boolean>(cartData?.status === 1 ? true : false);
  const [updateQuantity] = useUpdateItemMutation();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false); // State to track button disable/enable
  const navigate = useNavigate();

  console.log(quan, "quan");


  useEffect(() => {
    setIsButtonDisabled(false);
  }, []);

  const updateSelected = (currentQuantity: number, isSelected: boolean) => {
    const price = Number(cartData?.price);
    const total = currentQuantity * price

    if (isSelected) {
      const updatedItem = { ...cartData, quantity: currentQuantity, total: total };

      setSelected((prevSelected) => {
        const filtered = prevSelected.filter((item) => item.cart_id !== cartData.cart_id);
        return [...filtered, updatedItem];
      });
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((item) => item.cart_id !== cartData.cart_id)
      );
    }
  };



  const updateQuantityFun = async (newQuantity: number, isSelected: boolean) => {
    setIsLoading(true);
    try {
      const body = {
        cartId: cartData?.cart_id,
        quantity: newQuantity,
        status: isSelected ? 1 : 0,
      };
      console.log(body, "update ki body")
      const res = await updateQuantity(body).unwrap();
      if (res?.availableProduct === res?.quantity || res?.status !== 200) {
        setIsButtonDisabled(true);
        showError("Sorry, Item is out of stock")
        setQuan((prev) => prev - 1);
        return;
      }
      else {
        setIsButtonDisabled(false);
      }
      fetchData()
      setIsLoading(false)
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error);
    }
  };

  const incrementQuantity = () => {
    setQuan((prevQuan) => {
      const newQuan = prevQuan + 1;
      updateSelected(newQuan, isChecked);
      updateQuantityFun(newQuan, isChecked); // Update on change
      return newQuan;
    });
  };

  const decrementQuantity = () => {
    setQuan((prevQuan) => {
      const newQuan = Math.max(1, prevQuan - 1);
      updateSelected(newQuan, isChecked);
      updateQuantityFun(newQuan, isChecked); // Update on change
      return newQuan;
    });
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuan = Math.max(1, parseInt(e.target.value));
    setQuan(newQuan);
    updateSelected(newQuan, isChecked); // Update selected items
    updateQuantityFun(newQuan, isChecked); // Update on change
  };


  const truncateText = (text: string, wordLimit: number) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const deleteItemsFromCart = async () => {
    setIsLoading(true)
    try {
      const res = await deleteItem({ id: cartData?.cart_id }).unwrap();
      if (res?.status === 200) {
        setIsLoading(false)
        showToast("Product removed from cart");
        fetchData();
      }
      setIsLoading(false);
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error)
    }
  }

  // const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = e.target.checked;
  //   setIsChecked(checked);
  //   updateSelected(quan, checked); // Update selected items
  //   updateQuantityFun(quan, checked); // Update status
  // };


  return (
    <>
      <Loader isLoad={isLoading} />
      <tr>
        {/* <td>
          <label className="checkbox_label">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          </label>
        </td> */}
        <td>
          <figure className="product_image">
            <img
              onClick={() => navigate(`/product-details/${cartData?.product_id}`)}
              src={cartData?.image} alt="Icon" />
          </figure>
        </td>
        <td>
          <b>{truncateText(cartData?.name, 7)}</b>
        </td>
        <td>$ {cartData?.price}</td>
        <td>
          <div className="quantity">
            <button className="minus" aria-label="Decrease" onClick={decrementQuantity} disabled={quan === 1} >
              <RemoveIcon />
            </button>
            <input
              type="number"
              className="input-box"
              value={cartData?.quantity}
              onChange={handleQuantityChange}
            />
            <button className="plus" aria-label="Increase" onClick={incrementQuantity} disabled={isButtonDisabled} >
              <AddIcon />
            </button>
          </div>
        </td>
        <td>$ {(Number(cartData?.price) * cartData?.quantity).toFixed(2)}</td>
        <td>
          <Tooltip title="Remove item from cart">
            <button className="icon_btn"
              onClick={deleteItemsFromCart}>
              <img src="static/images/delete_icon.svg" alt="Icon" />
            </button>
          </Tooltip>
        </td>
      </tr>
    </>
  );
};

export default CartItem;
