import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal, Button } from "@mui/material";
import { generateResponsiveStyle } from "../utils/authModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarIcon from "@mui/icons-material/Star";
import useAuth from "../hooks/useAuth";
import { usePostAddAppointmentMutation } from "../services/main";
import {
  STORAGE_KEYS,
  getFromStorage,
  showError,
} from "../constants";
import moment from "moment";
type Slot = {
  id: number;
  isbooked: number;
  shift_id: number;
  slot_end_time: string;
  slot_start_time: string;
};

interface AcupunctureModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTime: number | undefined;
  setSelectedTime: Dispatch<SetStateAction<number | undefined>>;
  selectField: any;
  currentDate: any;
  duration: string;
  details: any;
  setDetails: Dispatch<SetStateAction<any>>;
  setCurrentDate: any;
  doctorData: any;
  selectedPrice: number;
  selectField2: string;
  setSelectField2: Dispatch<SetStateAction<string>>;
  slotArray: Slot[];
  appointmentType: string | "";
  currentDate1: any;
  currentTime: any;
  currentDay: any;
}
export default function AcupunctureModal({
  open,
  onClose,
  setOpen,
  selectedTime,
  selectField,
  currentDate,
  duration,
  currentDay,
  currentTime,
  details,
  setDetails,
  setCurrentDate,
  currentDate1,
  doctorData,
  selectedPrice,
  setSelectedTime,
  selectField2,
  setSelectField2,
  slotArray,
  appointmentType
}: AcupunctureModalProps) {
  const navigate = useNavigate();
  const userData = useAuth();
  console.log(userData, "DATA");
  const [bookAppointment] = usePostAddAppointmentMutation();
  const userId = getFromStorage(STORAGE_KEYS.userId);

  const formatDate = (date: any) => {
    // Format the date in 'Monday, Jan 16, 2024' format
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handlePrevDayClick = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 1); // Subtract 1 day
    setCurrentDate(prevDate);
  };

  // Function to handle click on the right arrow (next day)
  const handleNextDayClick = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1); // Add 1 day
    setCurrentDate(nextDate);
  };

  const firstTherapyPrice = doctorData?.pricing?.therapy_prices;

  let therapyItems: { name: string; price: number }[] = [];

  try {
    const therapyPricesObj = JSON.parse(firstTherapyPrice);
    if (therapyPricesObj && typeof therapyPricesObj === "object") {
      therapyItems = Object.entries(therapyPricesObj).map(
        ([name, price]: any) => ({
          name,
          price: parseFloat(price), // Convert price to a number if needed
        })
      );
    }
  } catch (error) {
    console.error("Error parsing therapy prices JSON:", error);
  }



  const handleBookAppointment = async () => {

    const body = {
      user_id: userId,
      status: 2,
      sessionFee: selectField2 === "1" ? Number(doctorData?.pricing?.new_patient) : Number(doctorData?.pricing?.followup),
      price: selectField2 === "1" ? Number(selectedPrice) + Number(doctorData?.pricing?.new_patient) : Number(selectedPrice) + Number(doctorData?.pricing?.followup),
      doctor_name: doctorData?.first_name + " " + doctorData?.last_name,
      visit_type: appointmentType,
      appointment_date_time: currentDate
        ? formatDate(currentDate) + " " + moment(details?.startTime, 'HH:mm:ss').format('hh:mm A')
        : "",
      patient_name: userData?.name,
      doctor_id: doctorData?.id,
      sub_category: selectField === "0" ? "" : selectField,
      slot_id: selectedTime,
      patient_type: selectField2 === "1" ? 1 : 0,
      slot_start_time: details?.startTime,
      slot_end_time: details?.endTime,
      slot_duration: duration,
    };

    console.log(body, "body for book appointment");

    try {
      const res = await bookAppointment(body).unwrap();
      if (res?.status === 200) {
        const data = {
          bookingNo: res?.data?.booking_number,
          price: selectField2 === "1" ? doctorData?.pricing?.new_patient : doctorData?.pricing?.followup
        }
        // showToast(res?.message);
        navigate("/doctor-booking", { state: { data: data, from: "appointmentBooking" } });
      } else {
        showError(res?.message);
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.data?.errors[0])
    }
  };

  return (
    <Modal
      className="modal booking_modal"
      id="slotsModal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="inner">
            <figure className="booking_image">
              <img src={doctorData?.profile_photo ? doctorData?.profile_photo : "/static/images/doctor.png"} alt="Icon" />
            </figure>
            <div className="booking_details hd_4">
              <p className="c_primary">{therapyItems[0]?.name}</p>
              <h2>
                {`Dr. ${doctorData?.first_name || "-"} ${doctorData?.last_name || ""
                  }`}
              </h2>
              <p>
                <small>{doctorData?.experience + " years of experience" || ""}</small>
              </p>
              <div className="store_rating">
                <StarIcon /> {doctorData?.rating || "0"}
              </div>
            </div>
          </div>
          <form>
            <div className="slots">
              <h6>Select Slot</h6>
              <div className="doctor_date">
                <a onClick={handlePrevDayClick}>
                  <ArrowBackIosNewIcon />
                </a>
                <span>{currentDate ? formatDate(currentDate) : ""}</span>
                <a onClick={handleNextDayClick}>
                  <ArrowForwardIosIcon />
                </a>
              </div>

              <div className="slot_list">
                {slotArray.filter((item) => {
                  const slotEndTime = moment(item?.slot_start_time, 'HH:mm:ss');
                  if (currentDay === currentDate1) {
                    return slotEndTime.isAfter(currentTime);
                  }
                  return true;
                })?.map((item) => {
                  const isBookedStyle: React.CSSProperties = item?.isbooked === 1
                    ? {
                      color: 'gray',
                      backgroundColor: 'lightgray',
                      pointerEvents: 'none' as 'none',
                      textDecoration: 'line-through',
                      cursor: 'default'
                    }
                    : {};

                  let backgroundColor;

                  if (selectedTime === item?.id) {
                    backgroundColor = '#00C408';
                  } else if (item?.isbooked === 1) {
                    backgroundColor = 'lightgray';
                  } else {
                    backgroundColor = '#29AB87';
                  }

                  const selectedStyle: React.CSSProperties = {
                    backgroundColor
                  };

                  return (
                    <label
                      key={item?.id}
                      onClick={() => {
                        if (item?.isbooked !== 1) {
                          setSelectedTime(item?.id);
                          setDetails({
                            startTime: item?.slot_start_time,
                            endTime: item?.slot_end_time
                          });
                        }
                      }}
                      style={{ ...isBookedStyle, ...selectedStyle }}
                      className="label_chip"
                    >
                      {moment(item?.slot_start_time, 'HH:mm:ss').format('hh:mm A') +
                        '-' +
                        moment(item?.slot_end_time, 'HH:mm:ss').format('hh:mm A')}
                      <input type="radio" name="slot" value="slot1" />
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="btn_flex">
              <a
                className="c_danger"
                href="javascript:void(0)"
                onClick={() => setOpen(false)}
              >
                Cancel
              </a>
              <Button
                onClick={handleBookAppointment}
                className="btnn btn_xsm btn_primary"
              >
                Book Now
              </Button>
            </div>
          </form>
        </div>
      </div >
    </Modal >
  );
}
