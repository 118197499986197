

/* eslint-disable jsx-a11y/anchor-has-content */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import { Doctors, WeeklySchedule } from "../types/General";
import moment from "moment";

interface doctorProps {
  doctorsData: Doctors[];
  type: string;
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>
}

export default function AcupunctureCard({ doctorsData, currentDate, setCurrentDate, type }: doctorProps) {

  const navigate = useNavigate();

  const formatDate = (date: any) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  // const getSlotArray = (weekOperationalHours: WeeklySchedule[]): string[] => {
  //   const formattedDate = formatDate(currentDate);
  //   const dayMap: Record<string, keyof WeeklySchedule> = {
  //     Monday: 'monday',
  //     Tuesday: 'tuesday',
  //     Wednesday: 'wednesday',
  //     Thursday: 'thursday',
  //     Friday: 'friday',
  //     Saturday: 'saturday',
  //     Sunday: 'sunday',
  //   };

  //   const dayName = Object.keys(dayMap).find((d) => formattedDate.includes(d));

  //   if (dayName) {
  //     const dayKey = dayMap[dayName];
  //     return weekOperationalHours?.[0]?.[dayKey] || [];
  //   }
  //   return [];
  // };

  const getSlotArray = (timingData: any) => {
    if (formatDate(currentDate).includes("Monday")) {
      return (timingData[0]?.slots || [])
    } else if (formatDate(currentDate).includes("Tuesday")) {
      return (timingData[1]?.slots || [])
    } else if (formatDate(currentDate).includes("Wednesday")) {
      return (timingData[2]?.slots || [])
    } else if (formatDate(currentDate).includes("Thursday")) {
      return (timingData[3]?.slots || [])
    } else if (formatDate(currentDate).includes("Friday")) {
      return (timingData[4]?.slots || [])
    } else if (formatDate(currentDate).includes("Saturday")) {
      return (timingData[5]?.slots || [])
    } else if (formatDate(currentDate).includes("Sunday")) {
      return (timingData[6]?.slots || [])
    }
  }


  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };



  return (
    <>
      {doctorsData?.length
        ? doctorsData?.map((item: any) => {
          const slotArray = getSlotArray(item?.shifts);

          return (
            <div className="box_single" key={item?.id}>
              <a
                href="javascript:void(0)"
                onClick={() => navigate(`/doctor-description/${item?.id}`)}
              ></a>
              <figure>
                <img
                  src={
                    item?.profile_photo
                      ? item?.profile_photo
                      : "static/images/doctor.png"
                  }
                  alt="icon"
                />
              </figure>
              <div className="info">
                <ul>
                  <li className="c_primary">{item?.providers[0]?.subcategories ? truncateText(item.providers[0].subcategories.replace(/[\[\]"]/g, ''), 4) : ""}</li>
                  <li className="store_rating">
                    <StarIcon /> {item?.rating || "0"}
                  </li>
                </ul>
                <h3>Dr. {`${item?.first_name} ${item?.last_name}`}</h3>
                {item?.experience ? (
                  <p>{`${item?.experience} years  of experience` || "-"}</p>
                ) : (
                  ""
                )}

                {item?.providers[0]?.category_names ? (
                  <p>
                    {item?.providers[0]?.category_names}
                  </p>
                ) : (
                  ""
                )}

                <div className="slot_list">
                  {slotArray?.length > 0
                    ? slotArray.map((val: any, idx: number) => (
                      <span
                        key={idx}
                        onClick={() => navigate(`/doctor-description/${item?.id}`)}
                      >
                        {moment(val?.slot_start_time, 'HH:mm:ss').format('hh:mm A') + "-" + moment(val?.slot_end_time, 'HH:mm:ss').format('hh:mm A')}
                      </span>
                    ))
                    : ('No available slots for today')}
                </div>
              </div>
            </div>
          );
          return null;
        })
        : ""}
    </>
  );
}
