import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    src: string;
};

const PicPreview = ({ open, setOpen, src }: props) => {

   

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="logout_mdl"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: 600,
                width: 800,
                bgcolor: "#ffff",
                // border: "1px solid #fff",
                boxShadow: 24,
                p: 6,
                borderRadius: 3,
                outline: "none",
            }}>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
               
                <div className="flexdiv">
                    <Box sx={{ textAlign: "center" }}>
                        <figure>
                            <img width={700} height={500} src={src ? src : "/static/images/productPlace.jpeg"} alt="icon" />
                        </figure>
                    </Box>
                </div>
            </Box>
        </Modal >
    );
};

export default PicPreview;
