import { Modal } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import { useGetRatingMutation, usePostRatingsMutation, useUpdateRatingMutation } from "../services/ratings";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showError, showToast } from "../constants";

type ratingType = {
  patient_id: number;
  doctor_id: number;
  appointment_id: number;

}
interface RatingModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  ratingData: ratingType;
  getAppointmentHidtory: () => void;
}

const RatingModal = ({ open, setOpen, onClose, ratingData, getAppointmentHidtory }: RatingModalProps) => {
  const [addReview] = usePostRatingsMutation();
  const [getReviewById] = useGetRatingMutation();
  const [data, setData] = useState<any>();
  const [updateRating] = useUpdateRatingMutation();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(ratingData, "ratingDataFromMOdal.....");


  const formatDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  // useEffect(() => {
  //   if (ratingData?.appointment_id !== undefined) {
  //     (async () => {
  //       try {
  //         const response = await getReviewById({
  //           id: ratingData?.appointment_id,
  //         }).unwrap();
  //         console.log(response, "response...............");
  //         if (response?.status === 200) {
  //           setData(response?.review);
  //           setRating(response?.review?.rating ?? 0);
  //           setReview(response?.review?.review ?? "");
  //         } else {
  //           setData(null);
  //           setRating(0);
  //           setReview("");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching existing rating:", error);
  //         showError("Error fetching existing rating");
  //       }
  //     })();
  //   }
  // }, [ratingData?.appointment_id]);
  // console.log(data, "data...........................");
  // console.log(ratingData?.appointment_id, "ratingData?.appointment_id")


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const reviewData = {
      // id: data?.id || null,
      review,
      rating: rating ?? 0,
      patient_id: ratingData?.patient_id,
      doctor_id: ratingData?.doctor_id,
      appointment_id: ratingData?.appointment_id,
      date: formatDate(),
    };
    console.log(reviewData, "rating data body ");

    const updateBody = {
      id: data?.id || null,
      review,
      rating: rating ?? 0,
    }
    try {
      if (data?.id) {
        const response = await updateRating(
          updateBody
        ).unwrap();
        console.log("Review updated:", response);
      } else {
        const response = await addReview(reviewData).unwrap();
        showToast("Review added")
        getAppointmentHidtory()
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      showError("Failed to submit or update review");
    } finally {
      setIsSubmitting(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      className="modal form_modal"
      id="orderInvoiceModal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={onClose}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        id="addRatingModal"
        tabIndex={-1}
        aria-labelledby="addRatingLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body hd_4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <h2>Rate and Review</h2>

              <form onSubmit={handleSubmit}>
                <div className="control_group">
                  <label>Rating</label>

                  <div className="star-rating">
                    <Rating
                      name="rating"
                      // value={Number(formik.values.rating)}
                      value={rating}
                      size="large"
                      // onChange={(event, newValue) => {
                      //   formik.setFieldValue("rating", newValue);
                      // }}
                      onChange={(event, newValue) => setRating(Number(newValue))}
                    />
                  </div>
                </div>
                <div className="control_group">
                  <label>Review</label>
                  <textarea
                    name="review"
                    placeholder="Enter here"
                    // value={formik.values.review}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={review}
                    onChange={(e) => setReview(e.target.value)}

                  />
                </div>
                <div className="btn_flex" >
                  <a className="c_danger" onClick={() => setOpen(false)}>Cancel</a>
                  <button
                    type="submit"
                    className="btnn btn_xsm btn_primary"
                    disabled={isSubmitting}
                  // onClick={() => setOpen(false)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RatingModal;


