/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import OrderInvoice from '../../Modals/orderInvoiceModal';
import { Order } from '../../types/General';
import { useLazyGetOrderHistoryQuery } from '../../services/cart';
import { Loader, STORAGE_KEYS, getFromStorage, showError } from '../../constants';
import { useLazyGetOrderInvoiceQuery } from '../../services/main';
import SitePagination from '../../components/Pagination';
import { socket } from '../../utils/socket';

export default function OrderHistory() {
    const [open, setOpen] = useState(false);
    const [getOrders] = useLazyGetOrderHistoryQuery();
    const [orders, setOrders] = useState<any>();
    const [itemData, setItemData] = useState<Order>();
    const handleCloseModal = () => {
        setOpen(false);
    };
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [downloadInvoice] = useLazyGetOrderInvoiceQuery();
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(totalCount / 5);
    const userId = getFromStorage(STORAGE_KEYS.userId) as any;
    const [socketData, setSocketData] = useState<any>()

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };

    console.log(orders, "orders");

    const fetchOrders = async () => {
        setIsLoading(true)
        try {
            const res = await getOrders({ page: page }).unwrap();
            if (res?.status === 200) {
                setOrders(res?.orders?.data);
                setTotalCount(res?.orders?.total);
            }
            setIsLoading(false)
        } catch (errors: any) {
            setIsLoading(false)
            showError(errors?.error);
        }
    }


    const fetchInvoice = async (id: any) => {
        setIsLoading(true)
        try {
            const res: any = await downloadInvoice({ id: id }).unwrap();
            if (res?.status === 200) {
                window.open(res?.url);
                console.log(res, "rsess");
            }
            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false)
            showError(error?.message);
        }
    };
    console.log(socketData, "socket data in orders");



    useEffect(() => {
        socket?.emit("join", userId);
        socket.emit('fetch_orders', { userId })
        socket?.on("fetch_join", async (message: any) => {
            console.log(message, "room joined>>>>>>>>>>");
        });
        socket?.on('fetchOrderByUserId', (data: any) => {
            setSocketData(data)
        });
    }, [])


    useEffect(() => {
        fetchOrders();
    }, [])

    return (
        <>
            <div className="tab-pane">
                <Loader isLoad={isLoading} />
                <div className="account_head">
                    <h3>Orders History</h3>
                </div>
                {
                    orders?.length ? (
                        orders?.map((item: Order, i: number) => {
                            return (
                                <div className="orders_box">
                                    <div className="head">
                                        <ul>
                                            <li>
                                                <span>Order ID</span>
                                                <strong>#{item?.order_number}</strong>
                                            </li>
                                            <li>
                                                <span>Order Placed</span>
                                                <strong>{item?.order_date_display}</strong>
                                            </li>
                                            <li>
                                                <span>Total</span>
                                                <strong >${item?.total_amount}</strong>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><a className="btnn btn_primary"
                                                onClick={() => fetchInvoice(item?.id)}
                                            >Download Invoice</a></li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <div className="order_table table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item?.sold_product?.map((temp) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <figure className="order_image">
                                                                            <img src={temp?.image} alt="icon" />
                                                                            <figcaption>{temp?.product_name}</figcaption>
                                                                        </figure>
                                                                    </td>
                                                                    <td>${temp?.price_per_product}</td>
                                                                    <td>{temp?.quantity}</td>
                                                                    <td>${temp?.quantity * Number(temp?.price_per_product)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="head">
                                        <ul>
                                            <li>
                                                {/* <span>Product Status: {socketData[i]?.order_status ? socketData[i]?.order_status.toUpperCase() : item?.order_status.toUpperCase()}</span> */}
                                                <span>Product Status: {socketData?.data[i]?.order_status ? socketData?.data[i]?.order_status.toUpperCase() : ""}</span>

                                                <span>Payment Status:  {item?.payment_status.toUpperCase()}</span>


                                                {/* <strong>
                                                    {new Date(item?.delivery_date) > new Date() ? " Deliver by" : "Delivered on"
                                                    } {socketData[i]?.delivery_date_display ? socketData[i]?.delivery_date_display : item?.delivery_date_display}
                                                </strong> */}
                                                <strong>
                                                    {new Date(socketData?.data[i]?.delivery_date) > new Date() ? " Deliver by" : "Delivered on"
                                                    } {socketData?.data[i]?.delivery_date_display ? socketData?.data[i]?.delivery_date_display : ""}
                                                </strong>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li><a href="javascript:void(0)" onClick={() => { setOpen(true); setItemData(item) }} data-bs-toggle="modal" data-bs-target="#orderInvoiceModal">View Invoice</a></li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <img
                                    src="/static/images/noapp.png"
                                    alt="icon"
                                    style={{ height: "100px", width: "100px", margin: "auto" }}
                                />
                                <h5>No recent orders</h5>
                            </div>
                        </div>
                    )
                }


            </div>
            {orders?.length ? (
                <SitePagination
                    module={orders}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                    setPage={setPage}
                />
            ) : undefined}

            <OrderInvoice open={open} onClose={handleCloseModal} setOpen={setOpen} item={itemData} />
        </>
    )
}
