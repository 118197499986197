import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { LocationClient, SearchPlaceIndexForTextCommand } from "@aws-sdk/client-location";
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button } from "@mui/material";
import { useLazyGetAllCategoriesQuery, useLazyGetAllDoctorsQuery } from "../services/main";
import { Doctors, ProductForHome, StoreUpdated, Subcategory } from "../types/General";
import { showError } from "../constants";
import { useLazyGetAllProductsQuery, useLazyGetAllStoresQuery } from "../services/store";

Amplify.configure(awsconfig);
export const SearchBar = ({ value }: { value: number }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [getAllDoctorsQuery, { isLoading }] = useLazyGetAllDoctorsQuery();
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [getStores] = useLazyGetAllStoresQuery();
  const [show, setShow] = useState<boolean>(false);
  const [storeData, setStoreData] = useState<StoreUpdated[]>([]);
  const [getCategories] = useLazyGetAllCategoriesQuery();
  const [categories, setCategories] = useState<Subcategory[]>([]);
  const [debounceTerm, setDebouncedSearchTerm] = useState<string>();
  const [doctorsData, setDoctorsData] = useState<Doctors[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<{ latitude: number, longitude: number } | null>(null);
  const [getAllProducts] = useLazyGetAllProductsQuery();
  const [productsData, setProductsData] = useState<ProductForHome[]>([]);

  const getAllDoctors = async () => {
    try {
      const res = await getAllDoctorsQuery({
        page: 1,
        search: debounceTerm ? debounceTerm : "all",
        experience: null,
        gender: "",
        price: "",
        rating: "",
        morning: "",
        afternoon: "",
        evening: "",
        weekend: "",
        type: 0,
        category: "",
        longitude: "",
        latitude: "",
      }).unwrap();

      if (res?.status === 200) {
        setDoctorsData(res?.doctors?.data);
      }
    } catch (error: any) {
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  };

  const fetchProducts = async () => {
    try {
      const res = await getAllProducts({ search: debounceTerm ? debounceTerm : "all" }).unwrap();
      if (res?.status === 200) {
        setProductsData(res?.product);
      }

    } catch (error: any) {
      if (error?.data?.message) {
        showError(error?.data?.message || "");
      }
    }
  }

  const fetchData = async () => {
    try {
      const res = await getCategories({ search: debounceTerm ? debounceTerm : "" }).unwrap();

      if (res?.status === 200) {
        setCategories(res?.subcategory);
      }
    } catch (errors: any) {
      console.log(errors?.error);
    }
  }

  const fetchSuggestions = async (text: string) => {
    const client = new LocationClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: "AKIAYS2NRO4XZCAHAP5Y",
        secretAccessKey: "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",
      }
    });

    const command = new SearchPlaceIndexForTextCommand({
      IndexName: 'CamlyAddressSearch',
      Text: text,
      MaxResults: 5
    });
    try {
      const response: any = await client.send(command);
      setSuggestions(response.Results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const fetchStores = async () => {
    try {
      const res = await getStores({
        search: debounceTerm ? debounceTerm : "all",
        page: 1,
        sort: "",
        latitude: "",
        longitude: "",
        currentLat:"",
        currentLong:"",
        nearby:"",
      }).unwrap();
      if (res?.status === 200) {
        setStoreData(res?.stores?.data || []);
      }
    } catch (error: any) {
      showError(error);
    }
  }

  const handleSuggestionClick = (suggestion: any) => {
    setInputValue(suggestion.Place.Label);
    setSuggestions([]);
    setSelectedLocation({
      latitude: suggestion.Place.Geometry.Point[1],
      longitude: suggestion.Place.Geometry.Point[0]
    });
  };

  const clearInput = () => {
    setInputValue('');
    setSuggestions([]);
  };

  useEffect(() => {
    fetchData();
    getAllDoctors();
    fetchProducts();
    fetchStores();
  }, [debounceTerm]);

  useEffect(() => {
    if (!searchValue) {
      setShow(false);
    }
    const delay = 1000;
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchValue]);

  return (
    <>
      <form action="" className="search_form">
        <div className="control_group">
          <input
            className="icon_search"
            type="text"
            placeholder={value === 2 ? "Medicines and health products" : "Therapies, Conditions, Practitioners..."}
            onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
              { setCategories([]); setSearchValue(val.target.value); setShow(true) }
            }}
            value={searchValue}
          />
          {searchValue && (
            <button className="clear_button" onClick={() => setSearchValue("")}><CloseIcon /></button>
          )}
        </div>
        {value !== 2 && show ? (
          <div>
            <ul className="suggestions_list" style={{
              width: "47%", padding: "8px 16px",
              borderBottom: "1px solid #ddd",
              borderRadius: "15px",
              backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}>
              {(doctorsData?.length || categories?.length) ? (
                <>
                  {doctorsData?.length ? (
                    doctorsData.map((item) => (
                      <li
                        key={item.id}
                        style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                        className="suggestion-item"
                        onClick={() => { setSearchValue(item.first_name + " " + item.last_name); setShow(false); }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={item?.profile_photo ? item?.profile_photo : "/static/images/dummy.png"}
                            style={{ marginRight: "10px" }}
                          />
                          {item.first_name + " " + item.last_name}
                        </div>
                        <p style={{ fontSize: "16px", fontStyle: "italic" }}>Doctor</p>
                      </li>
                    ))
                  ) : null}
                  {categories?.length ? (
                    categories.map((item) => (
                      <li
                        key={item.id}
                        style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                        className="suggestion-item"
                        onClick={() => { setSearchValue(item.sub_categories_name); setShow(false); }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={item?.image ? item?.image : "static/images/icon_05.svg"}
                            style={{ marginRight: "10px" }}
                          />
                          {item.sub_categories_name}
                        </div>
                        <p style={{ fontSize: "16px", fontStyle: "italic" }}>Conditions</p>
                      </li>
                    ))
                  ) : null}
                </>
              ) : (
                "No results found"
              )}
            </ul>
          </div>
        ) : value === 2 && show ? (
          <div>
            <ul className="suggestions_list" style={{
              width: "47%", padding: "8px 16px",
              borderBottom: "1px solid #ddd",
              borderRadius: "15px",
              backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}>
              {(storeData?.length || productsData?.length) ? (
                <>
                  {storeData?.length ? (
                    storeData.map((item) => (
                      <li
                        key={item.id}
                        style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                        className="suggestion-item"
                        onClick={() => { setSearchValue(item.name); setShow(false); }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={item?.images ? item?.images : "/static/images/store_attachment_01.jpg"}
                            style={{ marginRight: "10px" }}
                          />
                          {item.name}
                        </div>
                        <p style={{ fontSize: "16px", fontStyle: "italic" }}>store</p>
                      </li>
                    ))
                  ) : null}
                  {productsData?.length ? (
                    productsData.map((item) => (
                      <li
                        key={item.id}
                        style={{ marginBottom: "1px", display: "flex", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                        className="suggestion-item"
                        onClick={() => { setSearchValue(item.name); setShow(false); }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src="/static/images/download.jpg"
                            style={{ marginRight: "10px" }}
                          />
                          {item.name}
                        </div>
                        <p style={{ fontSize: "15px", fontStyle: "italic" }}>product</p>
                      </li>
                    ))
                  ) : null}
                </>
              ) : (
                "No results found"
              )}
            </ul>
          </div>
        ) : null}


        <hr />
        <div className="control_group">
          <div className="input_with_clear">
            <input
              className="icon_location"
              type="text"
              placeholder="Location"
              value={inputValue}
              onChange={handleInputChange}
            />
            {inputValue && (
              <button className="clear_button" onClick={clearInput}><CloseIcon /></button>
            )}
          </div>
          {inputValue && suggestions.length > 0 && (
            <ul className="suggestions_list">
              {suggestions.map((suggestion: any, index: number) => (
                <li
                  style={{ marginBottom: "1px" }}
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="suggestion-item"
                >
                  {suggestion.Place.Label}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            if (value === 0) {
              navigate("/docterlisting", { state: { search: searchValue, value: 1, category: "", latitude: selectedLocation?.latitude, longitude: selectedLocation?.longitude } });
            } else if (value === 1) {
              navigate("/docterlisting", { state: { search: searchValue, value: 2, category: "", latitude: selectedLocation?.latitude, longitude: selectedLocation?.longitude } });
            } else {
              navigate("/store-listing", { state: { search: searchValue, latitude: selectedLocation?.latitude, longitude: selectedLocation?.longitude } });
            }
          }}
          className="btnn btn_primary"
        >
          <img src="static/images/search_icon.svg" alt="Icon" />
        </button>
      </form >
    </>
  );
};
