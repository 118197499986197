/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CartItem from "../../components/cartItem";
import SimilarProductCard from "../../components/similarProductCard";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router-dom";
import { useLazyGetAllCartItemsQuery, useUpdateItemMutation } from "../../services/cart";
import { Loader, showError } from "../../constants";
import { CartItemType, HerbalProduct } from "../../types/General";

export const MyCart = () => {

  const navigate = useNavigate();
  const [getAllItems] = useLazyGetAllCartItemsQuery();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [subTotal, setSubTotal] = useState<number | undefined>(0);
  const [cartItems, setCartItems] = useState<CartItemType[]>([]);
  const [selected, setSelected] = useState<CartItemType[]>([]);
  const [similarData, setSimilarData] = useState<HerbalProduct[]>([]);
  const sub = (subTotal || 0).toFixed(2);
  const [shipping, setShipping] = useState<string>();

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const res = await getAllItems({}).unwrap();
      setIsLoading(false)
      if (res?.status === 200) {
        setCartItems(res?.cart);
        setShipping(res?.shipping_charges)
        setSimilarData(res?.similarproducts)
      }
    } catch (errors: any) {
      setIsLoading(false)
      showError(errors?.error);
    }
  }

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let calculatedSubTotal = 0;
      cartItems.forEach((item) => {
        if (item?.total) {
          calculatedSubTotal += item?.total;
        }
      });
      setSubTotal(calculatedSubTotal);
    } else {
      setSubTotal(0);
    }
  }, [cartItems]);
  const grandTotal = ((subTotal || 0) + (!subTotal ? 0 : Number(shipping))).toFixed(2);

  useEffect(() => {
    fetchData();
  }, [cartItems])

  console.log(selected, "selected items");
  console.log(selected?.length, "selected?.length");


  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="content cart_page">
        <section className="site_breadcrum">
          <div className="conta_iner">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>My Cart</li>
            </ul>
          </div>
        </section>

        <section className="cart_sc ub_spc">
          <div className="conta_iner">
            {cartItems?.length ? (
              <div className="table-responsive">
                <table className="table mb-0">
                  {cartItems?.length ? (
                    <thead>
                      <tr>
                        {/* <th></th> */}
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>) : (" ")}
                  <tbody>
                    {cartItems?.map((item) => {
                      return (
                        <CartItem
                          setSelected={setSelected}
                          fetchData={fetchData}
                          cartData={item}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              isLoading ? ("") : (
                <h1 style={{ color: "gray", textAlign: "center" }}>Cart is Empty </h1>
              )

            )}
            {cartItems?.length ? (
              <div className="sub_total">
                <p>
                  Subtotal <strong>$ {(subTotal || 0).toFixed(2)}</strong>
                </p>
                <p>
                  Shipping <strong>$ {shipping || 0} </strong>
                </p>
                <hr />
                <p>
                  Grand Total <strong>{"$ " + grandTotal}</strong>
                </p>
                <div className="btn_flex">
                  <a
                    onClick={() => navigate("/checkout", { state: { shippingFee: shipping, subTotal: sub, grandTotal: grandTotal } })}
                    className="btnn btn_xsm btn_primary br w_100"
                  >
                    Checkout
                  </a>
                </div>
              </div>
            ) : ("")}


            {cartItems?.length && similarData?.length ? (
              <div className="products_sc hd_3">
                <h2 className="fw_med">Similar Products</h2>
                <div className="product_listing">
                  <div className="gap_m">
                    {similarData?.map((item) => {
                      return (
                        <SimilarProductCard
                          status="open"
                          key={item?.id}
                          outlet_id={item?.outlet_id}
                          fetchData={fetchData}
                          product_id={Number(item?.id)}
                          product_image={item?.productImg}
                          product_name={item?.name}
                          product_desc={item?.about}
                          product_price={String(item?.price)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>) : ("")}
          </div>
        </section>
      </main>
    </Layout >
  );
};
