import { useEffect, useRef, useState } from "react";
import { SearchBar } from "../../components/searchBar";
import { Tab, Tabs } from "@mui/material";
// import Typed from "typed.js";
import { ReactTyped } from "react-typed";

const images = [
  "/static/images/hero_provider_attachment.png",
  "/static/images/hero_virtual_attachment.png",
  "/static/images/hero_herbal_attachment.png",
];

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="tab-pane"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const TopBanner = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  // const el1 = useRef(null);
  // const el2 = useRef(null);

  // useEffect(() => {
  //   const strings1 = ["acupuncture", "acupuncture", "acupuncture", "acupuncture"];
  //   const strings2 = ["cancer", "cancer", "cancer", "cancer"];

  //   const longestStringLength = Math.max(
  //     Math.max(...strings1.map(str => str.length)),
  //     Math.max(...strings2.map(str => str.length))
  //   );

  //   const typeSpeed1 = calculateTypingSpeed(strings1, longestStringLength);
  //   const typeSpeed2 = calculateTypingSpeed(strings2, longestStringLength);

  //   const typed1 = new Typed(el1.current, {
  //     strings: strings1,
  //     loop: true,
  //     startDelay: 100,
  //     typeSpeed: typeSpeed1,
  //     backSpeed: typeSpeed1 * 0.75,
  //     backDelay: 250
  //   });

  //   const typed2 = new Typed(el2.current, {
  //     strings: strings2,
  //     loop: true,
  //     startDelay: 100,
  //     typeSpeed: typeSpeed2,
  //     backSpeed: typeSpeed2 * 0.5,
  //     backDelay: 200
  //   });

  //   return () => {
  //     typed1.destroy();
  //     typed2.destroy();
  //   };
  // }, []);

  // const calculateTypingSpeed = (strings: any[], longestStringLength: number) => {
  //   const averageStringLength = strings.reduce((acc, str) => acc + str.length, 0) / strings.length;
  //   const speedMultiplier = longestStringLength / averageStringLength;
  //   return Math.max(50, Math.round(speedMultiplier * 160)); // Minimum speed of 50
  // };

  return (
    <section className="hero_sc">
      <div className="conta_iner">
        <div className="inner">
          <div className="gap_p">
            <div className="left_s">
              <h1>
                Find a local, licensed <br />
                <ReactTyped
                  strings={[
                    "Activated Charcoal Cleanse",
                    "Acupressure",
                    "Acupuncture",
                    "Affirmative Prayer",
                    "Alexander Technique",
                    "Alternative Cancer Treatments",
                    "Animal-Assisted Therapy",
                    "Anthroposophical Medicine",
                    "Apitherapy",
                    "Applied Kinesiology",
                    "Aquatherapy",
                    "Aromatherapy",
                    "Art Therapy",
                    "Asahi Health",
                    "Ashtanga Vinyasa Yoga",
                    "Astrology",
                    "Attachment Therapy",
                    "Auriculotherapy",
                    "Autogenic Training",
                    "Autosuggestion",
                    "Ayurveda",
                    "Bach Flower Remedies / Bach Flower Therapy",
                    "Bach Flower Remedies",
                    "Balneotherapy",
                    "Bates Method",
                    "Bibliotherapy",
                    "Bikram Yoga",
                    "Biodanza",
                    "Bioresonance Therapy",
                    "Blood Irradiation Therapies",
                    "Body Work(Alternative Medicine)",
                    "Chakra",
                    "Chelation Therapy",
                    "Chinese Food Therapy",
                    "Chinese Herbology",
                    "Chinese Pulse Diagnosis",
                    "Chiropractic",
                    "Chromotherapy / Color Therapy",
                    "Cinema Therapy",
                    "Coding (Therapy)",
                    "Colloidal Silver / Colloidal Silver Therapy",
                    "Colloidal Silver Therapy",
                    "Colon Cleansing",
                    "Craniosacral Therapy",
                    "Creative Visualization",
                    "Crystal Healing",
                    "Dance Therapy",
                    "Daoyin",
                    "Daoyin Therapy / Taoist Yoga",
                    "Detoxification",
                    "Detoxification Foot Baths",
                    "Dietary Supplements",
                    "Dowsing",
                    "Ear Candling",
                    "Eclectic Medicine",
                    "Electrohomeopathy",
                    "Electromagnetic Therapy",
                    "Enema / Colon Hydrotherapy",
                    "Energy Medicine",
                    "Earthing",
                    "Pranic Healing",
                    "Energy Psychology",
                    "Equine-Assisted Therapy",
                    "Faith Healing",
                    "Fasting",
                    "Feldenkrais Method",
                    "Feng Shui",
                    "Fire Cupping / Cupping",
                    "Flower Essence Therapy",
                    "Functional Medicine",
                    "German New Medicine",
                    "Grahamism",
                    "Graphology",
                    "Grinberg Method",
                    "Gua Sha Therapy",
                    "Hair Analysis (Alternative Medicine)",
                    "Hair Analysis",
                    "Halotherapy / Salt Therapy",
                    "Hatha Yoga",
                    "Havening",
                    "Hawaiian Massage",
                    "Herbal Therapy",
                    "Herbalism",
                    "Herbology",
                    "Hijama / Cupping",
                    "Traditional Chinese Medicine",
                    "Holistic Living",
                    "Holistic Medicine",
                    "Homeopathy",
                    "Home Remedies",
                    "Hydrotherapy",
                    "Hypnosis",
                    "Hypnotherapy",
                    "Introspection Rundown",
                    "Iridology",
                    "Isolation Tank",
                    "Isopathy",
                    "Iyengar Yoga",
                    "Jilly Juice",
                    "Kundalini Yoga",
                    "Laughter Therapy",
                    "Light Therapy",
                    "Macrobiotic Lifestyle",
                    "Magnet Therapy",
                    "Magnetic Healing",
                    "Manipulative Therapy",
                    "Manual Lymphatic Drainage",
                    "Martial Arts",
                    "Massage",
                    "Medical Intuition",
                    "Meditation",
                    "Visualization",
                    "Meridian (Chinese Medicine)",
                    "Mind–Body Intervention",
                    "Mindfulness Meditation",
                    "Moxibustion",
                    "Myofascial Release",
                    "Naprapathy",
                    "Natural Health",
                    "Natural Therapies",
                    "Nature Therapy",
                    "Naturopathic Medicine",
                    "Neuro-Linguistic Programming",
                    "New Thought",
                    "Numerology",
                    "Nutritional Healing",
                    "Nutritional Supplements",
                    "Mega-Vitamin Therapy",
                    "Orthopathy",
                    "Osteopathy",
                    "Pilates",
                    "Postural Integration",
                    "Prayer",
                    "Prokarin",
                    "Psychic Surgery",
                    "Qi",
                    "Qigong",
                    "Quantum Healing",
                    "Radionics",
                    "Rebirthing",
                    "Recreational Therapy",
                    "Reflexology",
                    "Reiki",
                    "Rolfing Structural Integration",
                    "Rosen Method",
                    "Self-Hypnosis",
                    "Shiatsu",
                    "Siddha Medicine",
                    "Siddha Yoga",
                    "Sivananda Yoga",
                    "Sonopuncture",
                    "Sophrology",
                    "Sound Therapy",
                    "Spiritual Mind Treatment",
                    "Structural Integration",
                    "Support Groups",
                    "Tai Chi",
                    "Tantra Massage",
                    "Tantric Yoga",
                    "Taoist Cognitive Therapy (TCT)",
                    "Thai Massage",
                    "Thalassotherapy",
                    "Therapeutic Horseback Riding",
                    "Therapeutic Touch",
                    "Tibetan Eye Chart",
                    "Trager Approach",
                    "Transcendental Meditation",
                    "Trigger Point",
                    "Tui Na Therapy",
                    "Unani Medicine",
                    "Urine Therapy",
                    "Uropathy",
                    "Vaginal Steaming",
                    "Vegetotherapy",
                    "Viniyoga",
                    "Vinyasa Yoga",
                    "Vipassana",
                    "Water Cure",
                    "Wellness",
                    "Wuxing (Chinese Philosophy) / Five Elements",
                    "Wuxing/ Five Elements",
                    "Yoga",
                    "Yoga Therapy",
                    "Zang Fu",
                    "Swedish Massage",
                    "TMJ Treatment",
                    "Sinus Treatment",
                    "Facial Rejuvenation Treatment",
                    "Infrared Sauna Therapy",
                    "Cognitive Behavioral Therapy",
                    "Maggot Therapy",
                    "Apitherapy"
                  ]}
                  typeSpeed={150}
                  backSpeed={150}
                  startDelay={100}
                  backDelay={200}
                  loop
                >
                  <span className="typing_text c_primary"></span>
                </ReactTyped>{" "}
                {/* <span>Colloidal Silver / Colloidal Silver Therapy</span> {" "} */}
                therapist <br />
                for{" "}
                <ReactTyped
                  strings={[
                    "Acne",
                    "ADD/ADHD",
                    "Adolescents Mental Therapy",
                    "Adult Mental Therapy",
                    "Alcohol Abuse",
                    "Allergies",
                    "Alopecia Areata",
                    "Alzheimer Management",
                    "Anxiety Management",
                    "Arthritis",
                    "Asthma",
                    "Auto-immune Disorders",
                    "Back/Neck/Shoulder/Foot Pain",
                    "Bell’s Palsy / Shingles",
                    "Bladder Disease",
                    "Breast Health",
                    "Breech Baby",
                    "Cancer Nutrition",
                    "Cancer Prevention & Healing",
                    "Cancer Side-effect of Chemo/Radiation",
                    "Cancer Treatment",
                    "Carpal Tunnel Syndrome",
                    "Celiac Disease",
                    "Cellulite Issues",
                    "Children Mental Therapy",
                    "CIDP (Chronic Inflammatory Demyelinating Polyneuropathy)",
                    "Cognitive Behavioral Therapy",
                    "Cold/Flu",
                    "Constipation",
                    "Coughing",
                    "Crohn’s Disease",
                    "Cystitis",
                    "Dementia",
                    "Depression",
                    "Diabetes",
                    "Digestive Diseases",
                    "Drug/Substance Abuse",
                    "Eczema/Dermatitis Treatment",
                    "Endometriosis",
                    "Epilepsy",
                    "Facial Lift & Rejuvenation",
                    "Family Planning",
                    "Fatigue/Low Immunity",
                    "Female Fertility Disorder",
                    "Fibromyalgia Treatment",
                    "Gambling Addiction",
                    "General Well-being/Longevity Improvement",
                    "GI/Esophageal Disorders",
                    "Headache/Migraine",
                    "Hearing",
                    "Heart Disease",
                    "Hepatitis",
                    "HIV/AIDS",
                    "Hyperlipidemia (High Cholesterol)",
                    "Hypertension (High Blood Pressure)",
                    "IBS/Diverticulitis",
                    "Inflammation Management",
                    "Insomnia Treatment",
                    "Intimacy Enhancement",
                    "Irritable Bowel Syndrome Treatment (IBS)",
                    "IVF & IUI Support",
                    "Joint Pain/Arthritis/Osteoarthritis",
                    "Kidney Diseases",
                    "Lack of Milk of Breastfeeding",
                    "Liver Diseases",
                    "Low Libido",
                    "Low Sperm Count & Motility",
                    "Low Testosterone/ED",
                    "Lupus",
                    "Lyme Disease",
                    "Male Fertility Disorder",
                    "Menopausal Symptoms Management",
                    "Menorrhea/Irregular Periods",
                    "Mental Health",
                    "Multiple Sclerosis (MS)/SLE",
                    "Nausea and Vomiting",
                    "Obesity",
                    "Oral Health",
                    "Osteoporosis",
                    "Pain Management",
                    "Parkinson Disease",
                    "PCOS, Uterus Fibroid",
                    "Pelvic Inflammatory Disease",
                    "Physical Fitness",
                    "PMS/Painful Menses",
                    "Post-term Pregnancy",
                    "Premature Ejaculation",
                    "Prostate Issues/High PSA Levels",
                    "Pulmonary Disease",
                    "Respiratory Diseases",
                    "Rheumatoid Arthritis",
                    "Rhinitis",
                    "Sciatica/Sports Injuries",
                    "Sexual Addiction",
                    "Sexual Transmitted Diseases (STD)",
                    "Sinusitis",
                    "Sinusitis",
                    "Sleep Apnea Treatment",
                    "Sleep Disorder",
                    "Smoking Cessation",
                    "Stomach Diseases",
                    "Stress/Anxiety/Depression",
                    "Stress Management",
                    "Stroke Rehabilitation",
                    "Temporomandibular Joint Disorder (TMJ)",
                    "Tennis Elbow",
                    "Tobacco Use",
                    "Trigeminal Neuralgia",
                    "Ulcer Treatment",
                    "Ulcerative Colitis",
                    "Urinary Incontinence, UTI",
                    "Vaginal Infection",
                    "Vaginismus",
                    "Vestibulodynia",
                    "Vulvodynia",
                    "Weight Gain",
                    "Women Hormone Imbalance",
                    "Women Infertility"
                  ]}
                  typeSpeed={250}
                  backSpeed={210}
                  startDelay={100}
                  backDelay={200}
                  loop
                >
                  <span className="typing_text c_danger"></span>
                </ReactTyped>{" "}
                treatment
              </h1>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs example"
            className="custom_tabs"
          >
            <Tab
              className={`${value === 0 ? "nav-link active" : "nav-link"}`}
              label="Providers"
            />
            <Tab
              className={`${value === 1 ? "nav-link active" : "nav-link"}`}
              label="Virtual"
            />
            <Tab
              className={`${value === 2 ? "nav-link active" : "nav-link"}`}
              label="Herbal Store"
            />
          </Tabs>
          <div className="tab-content">
            <TabPanel value={value} index={value}>
              <figure>
                <img
                  // style={{ width: "312px", height: "385px" }}
                  src={images?.[value]}
                  alt=""
                />
              </figure>
              <SearchBar value={value} />
            </TabPanel>
          </div>
        </div>
      </div>
    </section>
  );
};
