import React, { useEffect } from "react";
import "./App.css";
import Routing from "./routes";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";
import { socket } from "./utils/socket";


function App() {

  const navigate = useNavigate();
  const socketToken = getFromStorage(STORAGE_KEYS.token);
  const Socket_URL = "https://socketapi.camlyhealth.com/";
 

  function onConnect() {
    console.log(" socket connected");
  }

  function onDisconnect() {
    console.log("socket disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken) {
      const modifiedURL = `${Socket_URL}`;
      socket.io.uri = modifiedURL;

      if (!socket?.connected) {
        socket.connect();
      }
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    }
    else {
      socket.disconnect();
    }
  }, [socketToken]);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.data?.default}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                onClick={() => navigate("/recieved-notifications")}
              >
                {event?.data?.notification?.body}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
