// // eslint-disable-next-line import/no-extraneous-dependencies
import crypto from "crypto-js";
import CryptoJS from "crypto-js";

export function generateSEKAndHash() {
  const iv = "D904363DB8DACEB8".slice(0, 16);
  // const iv = "sX2wjaCx/2IQDGXN4OexKHCP5K2Wz/iDbbT2BPbyirQ=";
  try {
    const key = "D904363DB8DACEB8".slice(0, 16);
    // let dataPayload = {};
    // if (token === "" || token === null || token === undefined) {
    //   dataPayload = {
    //     timestamp: new Date(),
    //   };
    // } else {
    //   dataPayload = {
    //     authorization: `Bearer ${token}`,
    //     timestamp: new Date(),
    //   };
    // }
    const timestamp = Date.now();
    let dataPayload = { appkey: timestamp };
    let appkey = JSON.stringify(dataPayload);

    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      // hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return {
      hash: "",
      sek: "",
    };
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = ""; // No IV needed for ECB mode
  try {
    const key = CryptoJS.enc.Utf8.parse("D904363DB8DACEB8"); // 16 bytes key for AES-128
    const dataPayload = JSON.stringify(body);

    const encrypted = CryptoJS.AES.encrypt(dataPayload, key, {
      mode: CryptoJS.mode.ECB, // Use ECB mode
      padding: CryptoJS.pad.Pkcs7,
    });

    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      // hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
}

// export function generateEncryptedKeyBody(body: any) {
//   const iv = "D904363DB8DACEB8";
//   // const iv = "sX2wjaCx/2IQDGXN4OexKHCP5K2Wz/iDbbT2BPbyirQ=";
//   try {
//     const key = crypto.lib.WordArray.random(32);
//     let dataPayload = JSON.stringify(body);

//     const encrypted = crypto.AES.encrypt(dataPayload, key, {
//       iv: crypto.enc.Utf8.parse(iv),
//       mode: crypto.mode.CBC,
//     });
//     const encryptedHex = encrypted.ciphertext.toString();
//     const keyHash = key.toString();

//     return {
//       hash: keyHash,
//       sek: encryptedHex,
//       // deviceType: "WEB",
//     };
//   } catch (error) {
//     console.error("", error);
//     return null;
//   }
// }

// export function generateSEKAndHash() {
//   const algorithm = "aes-256-cbc";
//   const iv = "D904363DB8DACEB8";
//   try {
//     const key = crypto.lib.WordArray.random(32);
//     const timestamp = Date.now();
//     let dataPayload = { appkey: timestamp };
//     let appkey = JSON.stringify(dataPayload);
//     const encrypted = crypto.AES.encrypt(appkey, key, {
//       iv: crypto.enc.Utf8.parse(iv),
//       mode: crypto.mode.CBC,
//     });
//     const encryptedHex = encrypted.ciphertext.toString();
//     const keyHash = key.toString();

//     return {
//       hash: keyHash,
//       sek: encryptedHex,
//       appkey,
//     };
//   } catch (error) {
//     console.error("", error);
//     return null;
//   }
// }

// export function generateEncryptedKeyBody(body:any) {
//   const algorithm = "aes-256-cbc";
//   const iv = "D904363DB8DACEB8";
//   try {
//     const key = crypto.lib.WordArray.random(32);
//     let dataPayload = body;
//     let appkey = JSON.stringify(dataPayload);
//     const encrypted = crypto.AES.encrypt(appkey, key, {
//       iv: crypto.enc.Utf8.parse(iv),
//       mode: crypto.mode.CBC,
//     });
//     const encryptedHex = encrypted.ciphertext.toString();
//     const keyHash = key.toString();

//     return {
//       hash: keyHash,
//       sek: encryptedHex,
//     };
//   } catch (error) {
//     console.error("", error);
//     return null;
//   }
// }
