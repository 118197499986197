
import { Order } from "@stripe/stripe-js";
import { END_POINTS } from "../constants/url";
import { CartItemType, HerbalProduct, SimilarProducts, checkout } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
    message?: string;
    status: number;
}

type CartResponse = {
    cart: CartItemType[];
    status: number;
    shipping_charges: string;
    similarproducts: HerbalProduct[];
}
type checkoutResponse = {
    grandtotal: string;
    products: checkout[];
    shipping: string;
    subtotal: string;
    status: number;
    similarproducts: HerbalProduct[];
}

type Page = {
    page: number;
}

type ApiResponse = {
    orders: { data: Order[], total: number };
    status: number;
}

type update = {
    message: string;
    quantity: number;
    totalProduct: string;
    availableProduct: number;
    total: number;
    itemStatus: number;
    status: number;
}
export const cartApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllCartItems: builder.query<CartResponse, {}>({
            query: () => ({
                url: END_POINTS.cartItems,
                method: "GET",
            })
        }),

        deleteCartItem: builder.query<CommonResponseType, { id: number }>({
            query: ({ id }) => ({
                url: `${END_POINTS.deleteItems}?cartId=${id}`,
                method: "DELETE"
            })
        }),

        addTocart: builder.mutation<CommonResponseType, { product_id: number, quantity: number }>({
            query: (body) => ({
                url: END_POINTS.addToCart,
                method: "POST",
                body
            })
        }),

        emptyCart: builder.query<{ status: number }, {}>({
            query: (body) => ({
                url: END_POINTS.deleteCart,
                method: "DELETE",
            })
        }),

        updateItem: builder.mutation<CommonResponseType & update, {}>({
            query: (body) => ({
                url: `${END_POINTS.updateQuantity}`,
                method: "PUT",
                body
            })
        }),
        getCheckout: builder.query<checkoutResponse, { subTotal: number, shipping: number, grandtotal: number }>({
            query: ({ subTotal, shipping, grandtotal }) => ({
                url: `${END_POINTS.checkout}?subtotal=${subTotal}&shipping=${shipping}&grandtotal=${grandtotal}`,
                method: "GET",
            })
        }),
        cartPayNow: builder.mutation<{
            status: number; data: any
        }, {}>({
            query: (body) => ({
                url: END_POINTS.payCheckoutOrder,
                method: "POST",
                body
            })
        }),
        deleteCard: builder.mutation<{ status: number }, {}>({
            query: (body) => ({
                url: END_POINTS.deleteCard,
                method: "POST",
                body
            })
        }),
        // orderHistory: builder.query<{ status: number, message: string, data: any }, {}>({
        //     query: () => ({
        //         url: END_POINTS.orderHistory,
        //         method: "GET"
        //     })
        // }),
        getFaq: builder.query<{ status: number }, {}>({
            query: () => ({
                url: END_POINTS.userFaq,
                method: "GET",
            })
        }),
        getOrderHistory: builder.query < ApiResponse,{ page: number} > ({
            query: ({page}) => ({
                url: `${END_POINTS.orderHistory}?page=${page}`,
                method: "GET"
            })
        }),

    })


})

export const {
    useLazyGetAllCartItemsQuery,
    useLazyDeleteCartItemQuery,
    useAddTocartMutation,
    useLazyEmptyCartQuery,
    useUpdateItemMutation,
    useLazyGetCheckoutQuery,
    useCartPayNowMutation,
    useDeleteCardMutation,
    // useLazyOrderHistoryQuery,
    useLazyGetFaqQuery,
    useLazyGetOrderHistoryQuery,
} = cartApi;