import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type props = {
  module: any;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const SitePagination = ({
  module,
  page,
  onPageChange,
  totalPages,
  setPage,
}: props) => {
  const handlePageChange = (newPage: number) => {
    onPageChange(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="pagination">
      <div className="pages">
        <div
          className="prevbtn"
          onClick={() => {
            if (page > 1) {
              handlePageChange(page - 1);
            }
          }}
        >
          <ArrowBackIosNewIcon />
        </div>
        <div className="count">
          {totalPages > 6
            ? Array.from({ length: totalPages })
                .slice(0, 5)
                .map((_, index) => (
                  <div
                    className={page === index + 1 ? "actv" : "inActv"}
                    key={index}
                    onClick={() => {
                      if (page !== index + 1) {
                        handlePageChange(index + 1);
                      }
                    }}
                  >
                    {index + 1}
                  </div>
                ))
                .concat(
                  <span
                    key="ellipsis"
                    className="ellipsis"
                    style={{ color: "black" }}
                  >
                    ...
                  </span>,
                  <div
                    className="actv"
                    style={{
                      display:
                        page < 6 || page === totalPages ? "none" : undefined,
                    }}
                    key={totalPages}
                  >
                    {page}
                  </div>,
                  <div
                    className={page === totalPages ? "actv" : "inActv"}
                    key={totalPages}
                    onClick={() => handlePageChange(totalPages)}
                  >
                    {totalPages}
                  </div>
                )
            : Array.from({ length: totalPages }).map((_, index) => (
                <div
                  className={page === index + 1 ? "actv" : "inActv"}
                  key={index}
                  onClick={() => {
                    if (page !== index + 1) {
                      handlePageChange(index + 1);
                    }
                  }}
                >
                  {index + 1}
                </div>
              ))}
        </div>
        <div
          className="prevbtn"
          onClick={() => {
            if (page < totalPages) {
              handlePageChange(page + 1);
            }
          }}
        >
          <ArrowForwardIosIcon />
        </div>
      </div>
    </div>
  );
};

export default SitePagination;
