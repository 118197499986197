import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../layouts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextField } from "@mui/material";
import { usePostProfileSetupMutation } from "../../services/auth";
import { useFormik } from "formik";
import {
  showToast,
  showError,
  getFromStorage,
  STORAGE_KEYS,
} from "../../constants";
import { useLocation } from "react-router-dom";
import { isNumber } from "../../utils/validations";
import { Skip } from "../../components";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import Header from "../../layouts/header";
import { Footer } from "../../layouts/footer/footer";

const Step2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location;
  const userData = useAuth();
  const id = getFromStorage(STORAGE_KEYS.stepID);
  const [updateProfile] = usePostProfileSetupMutation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      health_condition_name: userData?.health_condition_name || "",
      first_diagnosed_on: userData?.first_diagnosed_on || "",
      status: userData?.status || 0,
      treated_by: userData?.treated_by || "",
      medication_taken: userData?.medication_taken || "",
      additional_note: userData?.additional_note || "",
    },
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        health_condition_name: formik.values.health_condition_name,
        first_diagnosed_on: formik.values.first_diagnosed_on,
        status: formik.values.status,
        treated_by: formik.values.treated_by,
        medication_taken: formik.values.medication_taken,
        additional_note: formik.values.additional_note,
        id: id,
      };

      try {
        const response = await updateProfile(body).unwrap();
        if (response.status === 200) {
          navigate("/step-3");
          const token = getFromStorage(STORAGE_KEYS.token);
          // dispatch(
          //   setCredentials({
          //     user: response?.data || null,
          //     token: token || null,
          //   })
          // );
          dispatch(
            setCredentials({
              user: response?.data || response?.user || null,
              token: token || null,
            })
          )
          console.log(response?.data, "response?.data");

        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Header cms />
      <main className="content auth_page">
        <section className="auth_sc uh_spc">
          <div className="conta_iner">
            <div className="inner">
              <div className="hd_4 text_center">
                <a onClick={() => navigate("/step-1")} className="back_icon">
                  <ArrowBackIcon />
                </a>
                <h2>Health data</h2>
                <Skip />
              </div>
              <div className="hd_6">
                <h3>Health conditions</h3>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="form"
                method="post"
              >
                <div className="control_group">
                  <label>Name of health condition</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    value={formik.values.health_condition_name}
                    onChange={(event) =>
                      formik.setFieldValue(
                        "health_condition_name",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="control_group">
                  <label>First diagnosed on</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    type="date"
                    value={formik.values.first_diagnosed_on}
                    onChange={(event) =>
                      formik.setFieldValue(
                        "first_diagnosed_on",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Status</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    value={formik.values.status}
                    onChange={(event) => {
                      const value = event.target.value;
                      // if (isNumber(value)) {
                      formik.setFieldValue("status", value);
                      // } 
                    }}
                  />
                </div>
                <div className="control_group">
                  <label>Treated by</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    value={formik.values.treated_by}
                    onChange={(event) =>
                      formik.setFieldValue("treated_by", event.target.value)
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Medication taken</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter here"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                    value={formik.values.medication_taken}
                    onChange={(event) =>
                      formik.setFieldValue(
                        "medication_taken",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="control_group">
                  <label>Additional notes</label>
                  <TextField
                    sx={{
                      height: "auto",
                      padding: "20px",
                      border: "1px solid lightgray",
                      borderRadius: " 50px"
                    }}
                    hiddenLabel
                    multiline
                    placeholder="Enter here"
                    fullWidth
                    rows={3}
                    inputProps={{ maxLength: 200 }}
                    value={formik.values.additional_note}
                    onChange={(event) =>
                      formik.setFieldValue(
                        "additional_note",
                        event.target.value
                      )
                    }
                  />
                </div>
                <div className="form_btn">
                  <button
                    type="submit"
                    className="btnn btn_xsm btn_primary w_100"
                  // onClick={() => navigate("/step-3")}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Step2;
