import { Dispatch, SetStateAction } from "react";

type checkoutAddresstype = {
  id: any;
  phone: string;
  zipCode: string;
  user_name: string,
  user_address: string,
  setSelectedAddress: Dispatch<SetStateAction<any>>
  setSelectedAddId: Dispatch<SetStateAction<any>>
  selectedAddress: any;
  selectedAddId:any;
}

const CheckoutAddress = ({
  id,
  phone,
  zipCode,
  user_name,
  user_address,
  setSelectedAddress,
  setSelectedAddId,
  selectedAddress,
  selectedAddId
}: checkoutAddresstype) => {
  return (
    <>
      <div className="address_list">
        <label>
          <span>
            <strong>{user_name}</strong>
            {user_address},{zipCode}
            <p>Phone:{phone}</p>
          </span>
          <input
            type="radio"
            checked={selectedAddId === id}
            name="address"
            value={selectedAddress}
            onClick={() => { setSelectedAddress(user_address); setSelectedAddId(id) }} />
        </label>
      </div>
    </>
  );
};

export default CheckoutAddress;