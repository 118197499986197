
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";
import { useLazyGetCmsDataQuery } from "../../services/auth";
import { useEffect, useState } from "react";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useLazyGetSocialLinksQuery, usePostEmailMutation } from "../../services/main";

// import { useState } from "react"
export const Footer = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const [socialLinks] = useLazyGetSocialLinksQuery();
  const [postSub] = usePostEmailMutation();
  const [links, setLinks] = useState<any>();
  const [email, setEmail] = useState<string>("");

  const fetchLinks = async () => {
    try {
      const res = await socialLinks({}).unwrap();
      if (res?.status === 200) {
        setLinks(res?.socialLinks)
      }
    } catch (error: any) {
      showError(error?.message || "Something went wrong")
    }
  }
  console.log(email, "email");

  const postEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    const body = {
      email: email
    }

    try {
      const res = await postSub(body).unwrap();
      if (res?.status === 200) {
        showToast("Email added successfully");
      }
      setEmail("");
    } catch (error: any) {
      setEmail("");
      showError(error?.message || "Something went wrong")
    }
  }



  useEffect(() => {
    fetchLinks();
  }, [])


  return (
    <>
      <footer className="site_footer bg_grey_dark">
        <div className="conta_iner">
          <div className="footer_nav hd_6 uh_spc">
            <div className="single">
              <a onClick={() => navigate("/")} className="site_logo">
                <figure>
                  <img src={`/static/images/camly_logo.svg`} alt="Camly Health Logo" />
                </figure>
              </a>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <div className="single">
              <h2>Quick Links</h2>
              <ul className="menu_list">
                <li>
                  <a href="javascript:void(0)" onClick={() => { window.scrollTo(0, 0); navigate("/") }}>Home</a>
                </li>
                <li>
                  <a href="javascript:void(0)" onClick={() => {
                    if (user) {
                      navigate("/profile", { state: "1" });
                    } else {
                      navigate("/login");
                      window.scrollTo(0, 0);
                    }
                  }}>Bookings</a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/privacy-policy")}
                    href="javascript:void(0)">Privacy Policy </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/terms-conditions")}
                    href="javascript:void(0)">Terms & conditions</a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/contact-us")}
                    href="javascript:void(0)">Contact us</a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/about-us")}
                    href="javascript:void(0)">About Us</a>
                </li>
              </ul>
            </div>
            <div className="single">
              <h2>Our Services</h2>
              <ul className="menu_list">
                <li>
                  <a
                    onClick={() => {
                      navigate("/docterlisting", { state: { search: "", value: "", category: "" } });
                      window.scrollTo(0, 0);
                    }}

                    href="javascript:void(0)">Make Appointment</a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/docterlisting", { state: { search: "", value: 2, category: "" } });
                      window.scrollTo(0, 0);
                    }}
                    href="javascript:void(0)">Virtual Consultation</a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/docterlisting", { state: { search: "", value: 1, category: "" } });
                      window.scrollTo(0, 0);
                    }}
                    href="javascript:void(0)">Visit Doctor Clinic</a>
                </li>
              </ul>
            </div>
            <div className="single">
              <h2>Subscribe</h2>
              <form className="form">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btnn btn_primary" onClick={(e) => postEmail(e)} >Subscribe</button>
              </form>
              <ul className="social_links">
                <li>
                  <a href="javascript:void(0)">
                    <img onClick={() => window.open(links[0]?.values)} src="/static/images/instagram_icon.svg" alt="Icon" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img onClick={() => window.open(links[1]?.values)} src="/static/images/twitter_icon.svg" alt="Icon" />
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img onClick={() => window.open(links[2]?.values)} src="/static/images/facebook_icon.svg" alt="Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copywrite">
          <div className="conta_iner">
            <p>© Copyright 2024 Camly Health, All Rights Reserved.</p>
          </div>
        </div>
      </footer >
    </>
  );
};
