import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB8NXH2SSnqd_NGelOrZVnNMST6dyexIH0",
  authDomain: "camly-14edb.firebaseapp.com",
  projectId: "camly-14edb",
  storageBucket: "camly-14edb.appspot.com",
  messagingSenderId: "325747714669",
  appId: "1:325747714669:web:a7f1d6734fd490010f6d64",
  measurementId: "G-WZ7FV4842M"
};
const app = initializeApp(firebaseConfig);
export default app;
