import React from "react";
import { useNavigate } from "react-router-dom";

export default function Skip() {

    const navigate = useNavigate();

    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
            style={{
                textDecoration: "underline",
                color: "#29AB87",
                float: "right",
                marginTop: "-35px"
            }}
            onClick={() => navigate("/", { replace: true })}
        >
            Skip
        </a >
    )
}