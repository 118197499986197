import { InputAdornment, Modal, TextField, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { generateResponsiveStyle } from "../utils/authModalStyle";
import { Loader, showError, showToast } from "../constants";
import { isNumber, isString } from "../utils/validations";
import { usePaymentTokenPostMutation } from "../services/auth";
interface CardModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchAllCards: () => void;
}
export default function AddCardModal({
  open,
  onClose,
  setOpen,
  fetchAllCards
}: CardModalProps) {
  const style = generateResponsiveStyle();
  const [addCard, { isLoading }] = usePaymentTokenPostMutation();


  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required("Name is required")
        .min(3, "Name should have minimum 3 letters")
        .max(20),
      cardNumber: Yup.string()
        .required("Card number is required")
        .label("Card number")
        .min(16, "Card number must contain atleast 16 digits"),
      expiryDate: Yup.string().required("Expiry date is required"),
      cvv: Yup.string()
        .required("Cvv is required")
        .label("CVV")
        .min(3, "Cvv should contain atleast 3 digits"),
    }),
    onSubmit: (values) => {
      formik.setSubmitting(true);

      // let data = {
      //   number: formik.values.cardNumber,
      //   exp_month: formik.values.expiryDate.slice(0, 2),
      //   exp_year: formik.values.expiryDate.slice(-4),
      //   cvc: formik.values.cvv,
      // };

      if ((window as any)?.Stripe) {
        // @ts-ignore
        window?.Stripe.card.createToken(
          {
            number: formik.values.cardNumber?.slice(0, 19),
            exp_month: String(formik.values.expiryDate).slice(0, 2),
            exp_year: String(formik.values.expiryDate).slice(-2),
            cvc: formik.values.cvv?.trim(),
            name: formik.values.cardHolderName?.trim(),
          },
          async (status: number, response: any) => {
            if (status === 200) {
              let body = {
                stripe_token: response?.id,
              };
              console.log(body, "body of stripe");

              try {
                const res = await addCard(body).unwrap();
                if (res?.status === 200) {
                  showToast("Card added successfully");
                  setOpen(false);
                  fetchAllCards();
                  formik.resetForm();
                }
              } catch (error: any) {
                showError(error?.data?.message || "");
              }
            } else {
              showError(response.error.message);
            }
          }
        );
      }

      formik.setSubmitting(false);
    },
  });

  return (
    <Modal
      className="modal form_modal"
      id="addCardModal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <Loader isLoad={isLoading} />
        <div className="modal-body hd_4">
          <div className="btn-close">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>{" "}
          <h2>Add New Card</h2>
          <form onSubmit={formik.handleSubmit} >
            <div className="control_group">
              <label>Card Number</label>
              <TextField
                hiddenLabel
                placeholder="Enter here"
                fullWidth
                className="text_field"
                name="cardNumber"
                inputProps={{ maxLength: 16 }}
                value={formik.values.cardNumber}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isNumber(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.cardNumber && formik.errors.cardNumber
                }

              />
            </div>
            <div className="control_group">
              <label>Card Holder Name</label>
              <TextField
                hiddenLabel
                placeholder="Enter here"
                fullWidth
                value={formik.values.cardHolderName}
                className="paymnt_modl_txtfld"
                name="cardHolderName"
                inputProps={{ maxLength: 20 }}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.cardHolderName &&
                  formik.errors.cardHolderName
                }

              />
            </div>
            <div className="gap_p">
              <div className="control_group w_50">
                <label>Expiry</label>
                <TextField
                  hiddenLabel
                  fullWidth
                  className="text_field"
                  placeholder="MM/YYYY"
                  inputProps={{ maxLength: 7 }}
                  value={formik.values.expiryDate}
                  name="expiryDate"
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const formattedValue = inputValue
                      .replace(/\D/g, "")
                      .replace(/(\d{2})(\d{0,4})/, "$1/$2");

                    formik.handleChange({
                      target: {
                        name: "expiryDate",
                        value: formattedValue,
                      },
                    });
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      formik.handleChange({
                        target: {
                          name: "expiryDate",
                          value: formik.values.expiryDate.slice(0, -1),
                        },
                      });
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.expiryDate && formik.errors.expiryDate
                  }

                />
              </div>
              <div className="control_group w_50">
                <label>CVV</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter here"
                  fullWidth
                  className="text_field"
                  name="cvv"
                  inputProps={{ maxLength: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="The CVV number is the last three digits on the back of your card">
                          <figure 
                          style={{cursor:"pointer"}}
                          className="cvv_img">
                            <img src="/static/images/cvv.svg" alt="" />
                          </figure>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.cvv}
                  onChange={(val) => {
                    if (
                      val.target.value === " " ||
                      val.target.value === "."
                    ) {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.cvv && formik.errors.cvv}
                />
              </div>
            </div>
            <div className="btn_flex">
              <a
                className="c_danger"
                href="javascript:void(0)"
                onClick={() => setOpen(false)}
              >
                Cancel
              </a>
              <button className="btnn btn_xsm btn_primary" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
