import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../layouts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OtpInput from "react-otp-input";
import FormControl from "@mui/material/FormControl";
import {
  usePostForgotPassMutation,
  usePostVerifyOtpMutation,
  useVerifyOtpMutation,
} from "../../services/auth";
import {
  STORAGE_KEYS,
  getFromStorage,
  setToStorage,
  showError,
  showToast,

} from "../../constants";
import { Alert, Box, Button, Modal } from "@mui/material";
import OTPInput from "react-otp-input";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { invalid } from "moment";
import { Footer } from "../../layouts/footer/footer";
import Header from "../../layouts/header";

const OtpVerify = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [optVerificationMutation] = usePostVerifyOtpMutation();
  const getData = getFromStorage(STORAGE_KEYS.res) as any;
  const state = location;
  console.log(state, "--");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [countDown, setCountDown] = useState<number>(59);
  const firstOtpInputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (firstOtpInputRef.current) {
  //     firstOtpInputRef.current.focus();
  //   }
  // }, []);


  console.log("state.state.page === signUp", state);


  const handleSubmit = async () => {
    const body = {
      ...(state?.state?.type === "phone"
        ? { country_code: state?.state?.countryCode }
        : {}),
      ...(state?.state?.type === "phone"
        ? { country_name: state?.state?.countryName }
        : {}),
      ...(state?.state?.type === "phone"
        ? { phone_number: state?.state?.phone }
        : { email: state?.state?.email }),

      otp: otp,
      id: state.state.page === "signUp" ? (JSON.parse(getData)?.id) : (JSON.parse(getData)?.user?.id),
    };

    console.log(body, "body for verify otp");

    try {
      const res = await optVerificationMutation(body).unwrap();
      if (state.state.page === "login") {
        navigate("/reset-password", { state: state });
      } else if (state.state.page === "signUp") {
        navigate("/profile-setup", { state: state });
      }

      if (res?.status === 200) {
        setToStorage(
          STORAGE_KEYS.token, (res?.token)
        );
        setToStorage(
          STORAGE_KEYS.userId,
          (res?.user?.id)
        )
        showToast("Otp verified succesfully")
        dispatch(
          setCredentials({
            user: res?.user || null,
            token: res?.token || null,
          })
        );
      } else {
        showError("Invalid Otp");
      }
    } catch (res: any) {
      showError("Invalid Otp");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <>
      <Header cms />
      <main className="content auth_page">
        <section className="auth_sc uh_spc">
          <div className="conta_iner">
            <div className="inner">
              <div className="hd_4 text_center">
                <a onClick={() => navigate("/login")} className="back_icon">
                  <ArrowBackIcon />
                </a>
                <h2>Verification</h2>
                <p>
                  {state?.state?.type === "phone" ? "We have sent a verification code to your phone number at " : "We have sent a verification code to your email at"}
                  <br />
                  <b className="c_primary">
                    {state?.state?.type === "phone"
                      ? state?.state?.countryCode + " " + state?.state?.phone
                      : state?.state?.email}
                  </b>
                </p>
              </div>

              <FormControl className="otp_input" sx={{ width: "100%" }}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  shouldAutoFocus
                  renderInput={(props) => <input {...props} />}
                  inputType="tel"
                />
                <br />
                {error && otp.length !== 4 ? (
                  <h6 className="err_msg" style={{ textAlign: "center" }}>
                    Please enter the OTP
                  </h6>
                ) : (
                  ""
                )}
              </FormControl>
              <div className="form_btn">
                <button
                  type="submit"
                  className="btnn btn_xsm btn_primary w_100"
                  onClick={handleSubmit}
                >
                  Next
                </button>
              </div>
              <div className="form_bottom">
                {/* <p>The verify code will expire in 00:59</p> */}
                {countDown === 0 ? (
                  <p>
                    <a
                      className="c_primary"
                      onClick={() => {
                        showToast("Otp resent successfully");
                        setCountDown(59);
                      }}
                    >
                      <b style={{ cursor: "pointer" }}>Resend Otp</b>
                    </a>
                  </p>
                ) : (
                  <p style={{ cursor: "pointer" }}>
                    The verification code will expire in{" "}
                    <span
                      style={{ margin: 0, color: "#D63853", paddingLeft: 3, cursor: "pointer" }}
                    >
                      00 : {countDown < 10 ? `0${countDown}` : countDown}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default OtpVerify;
