import { END_POINTS } from "../constants/url";
import { PaginatedStoresResponse, ProductDetails, ProductForHome, SimilarProducts, StoreData, StoreDetailUpdated, StoreUpdated } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
    message: string;
    status: number;
}

type ByIdRes = {
    message: string;
    status: number;
    store: StoreDetailUpdated;
}


type getAllResponse = {
    product: string;
    status: number;
    stores: {
        data: StoreUpdated[];
        total: number;
    }
}

type getAllProd = {
    product: ProductForHome[];
    status: number;
}



type ProductDetailsResponse = {
    how_to_use: string[];
    keybenefits: string[];
    productimages: string[];
    message: string;
    product: ProductDetails[];
    similar_products: SimilarProducts;
    status: number;
}



export const storeApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllStores: builder.query<getAllResponse, { search: string, page: number, sort: string, longitude: string, latitude: string, currentLat: any, currentLong: any, nearby: any }>({
            query: ({ search, page, sort, longitude, latitude,currentLat,currentLong,nearby }) => ({
                url: `${END_POINTS.getAllStores}?search=${search}&page=${page}&sort=${sort}&longitude=${longitude}&latitude=${latitude}&currentLat=${currentLat}&currentLong=${currentLong}&nearby=${nearby}`,
                method: "GET",
            })
        }),

        getStoreDetails: builder.query<ByIdRes, { id: string }>({
            query: ({ id }) => ({
                url: `${END_POINTS.getStoreDetails}/${id}`,
                method: "GET",
            })
        }),

        getProductDetails: builder.query<ProductDetailsResponse, { id: string }>({
            query: ({ id }) => ({
                url: `${END_POINTS.productDetails}/${id}`,
                method: "GET",
            })
        }),

        getAllProducts: builder.query<getAllProd, { search: string }>({
            query: ({ search }) => ({
                url: `${END_POINTS.allProducts}?search=${search}`,
                method: "GET",
            })
        }),

    })
})

export const {
    useLazyGetAllStoresQuery,
    useLazyGetStoreDetailsQuery,
    useLazyGetProductDetailsQuery,
    useLazyGetAllProductsQuery
} = storeApi;