import { Button, Modal, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const LoginModal = ({ open, setOpen, onClose }: LoginModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      className="modal form_modal"
      id="addAddressModal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body hd_4">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <h3 style={{ textAlign: "center" }}>
            To book an appointment you need to login first
          </h3>

          <div className="btn_flex">
            <a
              className="c_danger"
              href="javascript:void(0)"
              onClick={() => setOpen(false)}
            >
              Cancel
            </a>
            <Button
              type="submit"
              className="btnn btn_xsm btn_primary"
              onClick={() => navigate("/login")}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
