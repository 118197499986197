import React, { useEffect, useState } from "react";
import { PaymentDetailsCard } from "../../components";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router";
import StarIcon from "@mui/icons-material/Star";
import { useLocation } from "react-router-dom";
import { usePostAppointmentDetailsMutation } from "../../services/main";
import { Loader, STORAGE_KEYS, setToStorage, showError } from "../../constants";

export const DoctorBooking = () => {
  const [appointmentDetails, { isLoading }] = usePostAppointmentDetailsMutation();
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  console.log(state);


  const [appointmentData, setAppointmentData] = useState<any>();

  const getAppointmentDetails = async () => {
    const body = {
      booking_number: state?.data?.bookingNo,
    };
    try {
      const response = await appointmentDetails(body).unwrap();
      if (response?.status === 200) {
        setAppointmentData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  console.log(appointmentData, "appointmentData shdgh");

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  return (
    <>
      <Layout>
        <main className="content booking_page">
          <Loader isLoad={isLoading} />
          <section className="site_breadcrum">
            <div className="conta_iner">
              <ul>
                <li>
                  <a onClick={() => navigate("/")}>Home</a>
                </li>
                <li>
                  <a onClick={() => navigate("/docterlisting")}>
                    Doctor Listing
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate(`/doctor-description/${appointmentData?.doctor_id}`)}>
                    {appointmentData?.doctor_name || "-"}
                  </a>
                </li>
                <li>Booking</li>
              </ul>
            </div>
          </section>
          <section className="booking_sc ub_spc">
            <div className="conta_iner">
              <div className="booking_info">
                <div className="inner">
                  <figure className="booking_image">
                    <img
                      src={
                        appointmentData?.doctor?.profile_photo
                          ? appointmentData?.doctor?.profile_photo
                          : "/static/images/doctor.png"
                      }
                      alt="icon"
                    />
                    <figcaption>Online consultant</figcaption>
                  </figure>
                  <div className="booking_details hd_3">
                    <ul>
                      <li className="c_primary">
                        {appointmentData?.sub_category || "-"}
                      </li>
                      <li className="store_rating">
                        <StarIcon /> {appointmentData?.doctor?.rating || "0"}
                      </li>
                    </ul>
                    <h2>
                      {`${appointmentData?.doctor?.first_name || ""} ${appointmentData?.doctor?.last_name || ""}`}
                    </h2>
                    {
                      appointmentData?.doctor?.experience ? <p>{`${appointmentData?.doctor?.experience} years of experience`}</p> : ""
                    }

                    <p>
                      {appointmentData?.appointment_date_time || "-"}
                      {/* <strong className="c_primary">9:00 am</strong> */}
                    </p>
                  </div>
                </div>
                {/* <h3>
                  <strong>California medical center:</strong> 21 N Greenwood
                  Ave, Tulsa, OK 74120
                </h3> */}
                {appointmentData?.doctor?.degree &&
                  appointmentData?.doctor?.city &&
                  appointmentData?.doctor?.country &&
                  appointmentData?.doctor?.state ? (
                  <h3>
                    <strong>{appointmentData?.doctor?.degree || ""}</strong>
                    {`${appointmentData?.doctor?.city || ""} ${appointmentData?.doctor?.state || ""
                      } , ${appointmentData?.doctor?.country || ""}`}
                  </h3>
                ) : null}
              </div>
              <PaymentDetailsCard appointmentData={appointmentData} price={state?.data?.price} from="booking" />
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};
