import React from "react";
import { useNavigate } from "react-router";
import StarIcon from '@mui/icons-material/Star';
import { StoreData, StoreUpdated } from "../types/General";
import moment from "moment";

interface storeProps {
  storeData: StoreUpdated[];
}
export default function StoreInfoCard({ storeData }: storeProps) {
  const navigate = useNavigate();

  const isStoreOpen = (openingTime: string, closingTime: string) => {
    const currentTime = moment();
    const opening = moment(openingTime, 'HH:mm:ss');
    const closing = moment(closingTime, 'HH:mm:ss');
    return currentTime.isBetween(opening, closing);
  };

  return (
    <>
      {storeData?.length ? (
        storeData.map((item: StoreUpdated, index: number) => {
          const storeIsOpen = isStoreOpen(item.opening_time, item.closing_time);
          return(
            <div key={index}
              className={`box_single ${storeIsOpen ? "" : "closed"}`}
              onClick={() => navigate(`/store-details/${item?.id}`)}>
              <figure>
                <img
                  src={item?.images ?? "static/images/store_attachment_01.jpg"}
                  alt="store"
                />
              </figure>
              <div className="info">
                <h3>{item?.name}</h3>
                <p>Timing: {moment(item.opening_time, 'HH:mm:ss').format('hh:mm A') || ''} to {moment(item.closing_time, 'HH:mm:ss').format('hh:mm A') || ''}</p>
                <p>{item?.address}</p>
                <p>
                  <small>{item?.merchant[0]?.about_store ?? "No description available."}</small>
                </p>
              </div>
            </div>
          )
        })
      ) : (
        <p>We couldn't find any outlet for you</p>
      )}
    </>
  );
};


