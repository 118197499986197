import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../layouts";
import { Button } from "@mui/material";
import { Footer } from "../../layouts/footer/footer";
import Header from "../../layouts/header";
import { useLazyGetCmsDataQuery } from "../../services/auth";
import { Loader, showError } from "../../constants";


const AboutUs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    console.log(state, "state for about us");
    const [getCmsData, { isLoading }] = useLazyGetCmsDataQuery();
    const [cmsData, setCmsData] = useState<any>();


    const fetchCms = async () => {
        try {
            const res = await getCmsData({}).unwrap();
            if (res?.status === 200) {
                setCmsData(res);
            }

        } catch (errors: any) {
            showError(errors?.error)
        }
    }

    useEffect(() => {
        fetchCms();
    }, [])

    return (
        <>
            <Header cms />
            <Loader isLoad={isLoading} />
            <div className=" about_us">
                {/* <div className="about_flex">
                <div className="about_lft">
                    <div className="about_lft_udr">
                        <h1>About us</h1>
                        <p>
                            {state?.aboutUs || "-"}
                        </p>
                    </div>
                </div>
                <div className="about_rgt">
                    <figure>
                        <img src="/static/images/about_us.png" alt="" />
                    </figure>
                </div>
            </div> */}

                <div className="common_policy ">
                    <div className="about_content container">
                        <h2>About us</h2>
                        <p
                            className={cmsData?.aboutUs?.about ? "about_txt" : "about_txt2"}
                            dangerouslySetInnerHTML={{
                                __html: cmsData?.aboutUs?.about ? cmsData?.aboutUs?.about : "Data not found",
                            }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
