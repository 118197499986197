import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../layouts/header";
import { useLazyGetCmsDataQuery } from "../../services/auth";
import { Loader, showError } from "../../constants";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [getCmsData,{isLoading}] = useLazyGetCmsDataQuery();
    const [cmsData, setCmsData] = useState<any>();

    const fetchCms = async () => {
        try {
            const res = await getCmsData({}).unwrap();
            if (res?.status === 200) {
                setCmsData(res);
            }

        } catch (errors: any) {
            showError(errors?.error)
        }
    }

    useEffect(() => {
        fetchCms();
    }, [])



    useEffect(() => {
        if (window) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, []);

    return (
        <>
            <Header cms />
            <Loader isLoad={isLoading}/>
            <div className="common_policy privicy">
                <div className="container">
                    <div className="privt_one">
                        <h1>Privacy Policy</h1>
                        <p
                            className={cmsData ? "about_txt" : "about_txt2"}
                            dangerouslySetInnerHTML={{
                                __html: cmsData?.privacyPolicy?.privacy_policy ? cmsData?.privacyPolicy?.privacy_policy
                                    : "Data not Found",
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
