import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "../../layouts";
import Header from "../../layouts/header";
import { Footer } from "../../layouts/footer/footer";
import { useLazyGetCmsDataQuery } from "../../services/auth";
import { showError } from "../../constants";


const ContactUs = () => {
    const location = useLocation();
    const { state } = location;
    const [getCmsData] = useLazyGetCmsDataQuery();
    const [cmsData, setCmsData] = useState<any>();

    const fetchCms = async () => {
        try {
            const res = await getCmsData({}).unwrap();
            if (res?.status === 200) {
                setCmsData(res);
            }

        } catch (errors: any) {
            showError(errors?.error)
        }
    }

    useEffect(() => {
        fetchCms();
    }, [])


    return (
        <>
            <Header cms />
            <div className="common_policy contact_us">
                <div className="container">
                    <h1>Contact us</h1>
                    <div
                    // className="admin_dtls"
                    >
                        <p>
                            Email :
                            <a
                                href={cmsData ? `mailto:${cmsData?.contact?.email || ""}` : ""}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>{" " + cmsData?.contact?.email || " not provided"}</span>
                            </a>
                        </p>
                        <p>
                            Phone number :
                            <a
                                href={
                                    cmsData
                                        ? `tel: ${cmsData?.contact?.country_code}+" "+ ${cmsData?.contact?.phone_number || ""}`
                                        : ""
                                }
                            >
                                <span>
                                    <span>{cmsData?.contact?.country_code + " " + cmsData?.contact?.phone_number || " not provided"}</span>

                                </span>
                            </a>
                        </p>
                    </div>

                    {/* <div className="contact_frm">
                    <h2>{translation.Globals.help}</h2>

                    <form onSubmit={formik.handleSubmit} className="form_control">
                        <div className="conact_flx">
                            <div className="conact_one">
                                <InputField
                                    placeholder={translation.Globals.full_name}
                                    name="name"
                                    id="name"
                                    value={formik.values.name}
                                    inputProps={{ maxLength: 40 }}
                                    onChange={(val) => {
                                        if (
                                            val.target.value === " " ||
                                            val.target.value === "."
                                        ) {
                                        } else if (isString(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </div>
                            <div className="conact_one">
                                <InputField
                                    placeholder={translation.Globals.email}
                                    name="email"
                                    id="contact_email"
                                    value={formik.values.email}
                                    onChange={(val) => {
                                        if (
                                            val.target.value === " " ||
                                            val.target.value === "."
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </div>
                        </div>
                        <div style={{ marginBottom: 10 }} />

                        <TextField
                            type="text"
                            className="text_field text_area"
                            hiddenLabel
                            variant="outlined"
                            placeholder={translation.Globals.message}
                            multiline
                            rows={4}
                            fullWidth
                            name="message"
                            id="message"
                            inputProps={{ maxLength: 300 }}
                            value={formik.values.message}
                            onChange={(val) => {
                                if (val.target.value === " " || val.target.value === ".") {
                                } else {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            helperText={formik.touched.message && formik.errors.message}
                        />

                        <Button value={translation.Globals.contact_now} />
                    </form>
                </div> */}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
