/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import CartItem from "../../components/cartItem";
import SimilarProductCard from "../../components/similarProductCard";
import { Layout } from "../../layouts";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../constants";


export const Notification = () => {

  const navigate = useNavigate();

//   const fetchData = async () => {
//     try {
//       const res = await getAllItems({}).unwrap();
//       if (res?.status === 200) {
//         setCartItems(res?.cart);
//         setSimilarData(res?.similarproducts)

//       }
//     } catch (errors: any) {
//       showError(errors?.error);
//     }
//   }


  return (
    <Layout>
      {/* <Loader isLoad={isLoading} /> */}
      <main className="content cart_page">
        <section className="site_breadcrum">
          <div className="conta_iner">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>Notifications</li>
            </ul>
          </div>
        </section>

        {/* <section className="cart_sc ub_spc">
          <div className="conta_iner">
            {cartItems?.length ? (
              <div className="table-responsive">
                <table className="table mb-0">
                  {cartItems?.length ? (
                    <thead>
                      <tr>
                        {/* <th></th> 
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>) : (" ")}
                  <tbody>
                    {cartItems?.map((item) => {
                      return (
                        <CartItem
                          setSelected={setSelected}
                          fetchData={fetchData}
                          cartData={item}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1 style={{ color: "gray", textAlign: "center" }}>No notifications </h1>
            )}
            
          </div>
        </section> */}
      </main>
    </Layout >
  );
};
