import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const PleaseLoginModal = ({ open, setOpen }: props) => {
    const navigate = useNavigate();

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="logout_mdl"
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "#ffff",
                // border: "1px solid #fff",
                boxShadow: 24,
                p: 6,
                borderRadius: 3,
                outline: "none",
            }}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center", fontSize: "25px", color: "#000000" }}
                >
                    Kindly login to keep using our services !
                </Typography>

                <div className="flexdiv">
                    <Box sx={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                        <Button
                            // className="yesBtn"
                            sx={{ color: "#ffffff", border: "1px solid #252525", bgcolor: "#29AB87", margin: "5px" }}
                            onClick={() => {
                                setOpen(false);
                                navigate("/login");
                            }}
                        >
                            Login
                        </Button>
                        <Button
                            sx={{ color: "#ffffff", border: "1px solid #252525", bgcolor: "#29AB87", margin: "5px" }}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Box>

                </div>
            </Box>
        </Modal >
    );
};

export default PleaseLoginModal;
